import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core'
import { mainTheme } from '../assets/themes/MainTheme'

export default function LlNavLink ({ name, to, target, style }) {
  const theme = useTheme()
  const useStyles = makeStyles({
    root: {
      textDecoration: 'none',
      color: theme.palette.type === 'dark'
        ? mainTheme.palette.darkSecondary.main
        : mainTheme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.type === 'dark'
          ? mainTheme.palette.darkSecondary.hover
          : mainTheme.palette.secondary.hover
      }
    }
  })
  const classes = useStyles()
  return (
    <NavLink
      target={target}
      to={to}
      className={classes.root}
      style={style}
    >
      {name}
    </NavLink>
  )
}
