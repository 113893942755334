import React from 'react'

const ServerErrorPage = ({ location }) => {
  return (
    <div>
      <h1>{location.state.detail}</h1>
    </div>
  )
}

export default ServerErrorPage
