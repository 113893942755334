import React from 'react'
import HeroView from './HeroView'
import LightfieldView from './LightfieldView'
import FeatureCarousel from './FeaturedCarousel'
import TransformYourContentView from './TransformYourContentView'
import CommunityCards from './CommunityCards'

export default function CreatorsView () {
  return (
    <>
      <HeroView />
      <LightfieldView />
      <FeatureCarousel />
      <TransformYourContentView />
      <CommunityCards />
    </>
  )
}
