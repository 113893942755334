import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { ApolloProvider, ApolloClient, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/link-context'
import './index.scss'
import './i18n/i18n'
import * as serviceWorker from './serviceWorker'
import FontFaceObserver from 'fontfaceobserver'
import { MuiThemeProvider, CssBaseline } from '@material-ui/core'
import { theme } from './assets/themes/MainTheme'
import Routes from './routes/Routes'
import { cache } from './cache'

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

// small package to load font first
const font = new FontFaceObserver('campton-book', 'campton-light', 'campton-medium', 'nantes-bold')

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-api-key': process.env.REACT_APP_API_KEY,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  connectToDevTools: true
});

font.load().then(() => {
  ReactDOM.render(
    <Router>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
          <ScrollToTop />
           <CssBaseline />
          <Routes />
        </MuiThemeProvider>
      </ApolloProvider>
    </Router>,
    document.getElementById('root')
  )
})
serviceWorker.unregister()
