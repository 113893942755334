import React, { Fragment } from 'react'
import {
  makeStyles,
  Card,
  CardActions,
  Button,
  Grid,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import sharedCardStyles from './SharedCardStyles'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'

// function pxToRem (value) {
//   return `${value / 16}rem`
// }

export default function SharedCardHalfLg ({
  gridColumn,
  label,
  title,
  subtitle,
  summary,
  summary2,
  download,
  backgroundColor,
  fontVariant,
  toSubmitPath,
  toLearnMore,
  purpleButton,
  outlinedButton,
  listItemText,
  listItemText1,
  height,
  padding,
  href,
  paddingTop,
  paddingBottom,
  alignSelf
}) {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...sharedCardStyles(theme),
    m_card_half: {
      display: 'grid',
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3, 5, 5)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 5, 5)
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(5, 5, 5)
      },
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(5, 5, 5)
      }
    },
    m_cardHalf_content_container: {
      display: 'grid',
      gridTemplateRows: 'auto'
    },
    m_cardAction_btn_container: {
      [theme.breakpoints.down('xs')]: {
        '& :last-child': {
          marginTop: '1rem'
        }
      },
      alignSelf: 'end',
      display: 'grid',
      // gridGap: '1rem', //safari no likey
      [theme.breakpoints.up('sm')]: {
        '& :last-child': {
          marginLeft: '1rem'
        },
        justifyContent: 'flex-end',
        display: 'flex',
        gridTemplateColumns: '45% auto'
      }
    },
    a_NavLink_button: {
      color: 'white',
      textDecoration: 'none',
      fontSize: '0.875rem',
      textAlign: 'start'
    },
    m_title_container: {
      display: 'flex',
      gridRow: '1',
      gridColumn: '1 / span 2',
      paddingBottom: '1.5rem',
      [theme.breakpoints.down('md')]: {
        paddingBottom: '1rem'
      },
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '0.75rem'
      }
    },
    m_List_container: {
      gridColumn: '1 / span 2'
    },
    a_cardHalf_text_title: {
      alignSelf: 'flex-start',
      paddingRight: '0.75rem',
      [theme.breakpoints.up('sm')]: {
      // lineHeight: pxToRem(63.7)
      },
      [theme.breakpoints.up('xs')]: {
        paddingRight: '0.5rem'
      }
    },
    a_cardHalf_text_subtitle: {
      alignSelf: 'flex-end',
      lineHeight: '2.5rem',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '2rem'
      },
      [theme.breakpoints.down('xs')]: {
        lineHeight: '1.25rem'
      }
    },
    m_ListItem: {
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    }
  })
  const classes = useStyles()
  return (
    <Fragment>
      <Grid item xs={12} md={6} style={{ padding }}>
        <Card>
          <CardContent
            className={clsx(classes.m_card_half)}
            style={{ height, backgroundColor, paddingTop, paddingBottom }}
          >
            {
              label &&
            <Typography
              variant="overline"
              style={{ alignSelf }}
            >
              {label}
            </Typography>
            }
            <div className={classes.m_cardHalf_content_container}>
              <div className={classes.m_title_container}>
                <Typography
                  style={{ gridColumn }}
                  className={classes.a_cardHalf_text_title}
                  variant={fontVariant}
                >
                  {title}
                </Typography>
                <Typography
                  className={classes.a_cardHalf_text_subtitle}
                  variant="overline"
                >
                  {subtitle}
                </Typography>
              </div>
              <Typography
                className={classes.a_text_body1}
                variant="body1"
              >
                {summary}
              </Typography>
              {/* I'll make this DRY after launch */}
              {
                listItemText &&
                <div className={classes.m_List_container}>
                  <List dense >
                    <ListItem className={classes.m_ListItem}>
                      <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }} />
                      <ListItemText primary={listItemText} />
                    </ListItem>
                    <ListItem className={classes.m_ListItem}>
                      <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }}/>
                      <ListItemText primary={listItemText1} />
                    </ListItem>
                  </List>
                </div>
              }
              <Typography
                className={classes.a_text_body1}
                variant="body1"
                gutterBottom
              >
                {summary2}
              </Typography>
            </div>
            <CardActions disableSpacing={true} className={classes.m_cardAction_btn_container}>
              {toSubmitPath && (
                <Button
                  size="large"
                  variant="contained"
                  component={NavLink}
                  to={toSubmitPath}
                  style={{ gridColumn }}
                >
                  {purpleButton}
                </Button>
              )}
              {toLearnMore && (
                <Button variant="outlined" color="inherit" size="large" component={NavLink} to={toLearnMore} style={{ gridColumn }}>
                  {outlinedButton}
                </Button>
              )}
              {href && (
                <Button variant="outlined" color="inherit" size="large" href={href} style={{ gridColumn }}>
                  {outlinedButton}
                </Button>
              )}
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    </Fragment>
  )
}
