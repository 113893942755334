import { createMuiTheme } from '@material-ui/core/styles'

export const pxToRem = (value) => {
  return `${value / 16}rem`
}

// for palette and to use breakpoints in other theme need to have an abstracted theme. probably need to refactor using responsiveFont or with theme.typography methods define each font.
export const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#1a1a1a',
      gray5: '#313131',
      gray4: '#313131',
      gray3: '#4c4c4c',
      gray2: '#d0d0d0',
      gray1: '#f1f1f1',
      gray: '#f2f2f2',
      leiaLight: '#fafafa'
    },
    secondary: {
      main: '#8833F5',
      hover: '#6B2ACC',
      disabled: '#C89DFF'
    },
    darkSecondary: {
      main: '#c89dff',
      hover: '#e0c8ff',
      disabled: '#b098c3'
    },
    error: {
      main: '#EB5757'
    },
    darkError: {
      main: '#FF7777'
    }
  }
})

export const theme = createMuiTheme({
  palette: {
    text: {
      primary: '#1A1A1A'
    }
  },
  spacing: 8,
  // material style overrides
  overrides: {
    MuiButton: {
      contained: {
        color: '#fff',
        background: 'linear-gradient(122.4deg, #8833F5 35.57%, #EA40E8 95.59%)',
        '&:hover': {
          background: 'linear-gradient(142.24deg, #6B2ACC 24.21%, #e244e0 96.52%)'
        },
        '&:disabled': {
          color: '#fff',
          background: 'linear-gradient(115.84deg, #6B2ACC 24.21%, #EA40E8 96.52%, #CB3CC9 96.52%)',
          opacity: '0.5'
        }
      },
      textPrimary: {
        color: mainTheme.palette.secondary.main,
        '&:hover': {
          backgroundColor: 'rgba(136, 51, 245, 0.09)'
        }
      }
    },
    MuiCard: {
      root: {
        height: '100%'
      }
    },
    MuiLink: {
      root: {
        color: mainTheme.palette.secondary.main
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        color: mainTheme.palette.primary.gray2,
        '&$checked': {
          color: mainTheme.palette.secondary.main
        }
      }
    },
    MuiFormHelperText: {
      contained: {
        margin: 'none'
      }
    },
    MuiFormLabel: {
      // below colors for this element are not noted in style guide
      root: {
        '&$focused': {
          color: '#3E91C6'
        }
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          // backgroundColor: null
        }
      }
    },
    MuiOutlinedInput: {
      // below colors for this element are not noted in style guide
      root: {
        '& $notchedOutline': {
          borderColor: '#6A6A6A'
        },
        '&$focused $notchedOutline': {
          borderColor: '#3E91C6'
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: mainTheme.palette.primary.gray4
      },
      daySelected: {
        backgroundColor: mainTheme.palette.secondary.main,
        '&:hover': {
          backgroundColor: mainTheme.palette.secondary.hover
        }
      },
      dayDisabled: {
        color: mainTheme.palette.primary.gray2
      },
      current: {
        color: mainTheme.palette.secondary.main
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: 'red'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: mainTheme.palette.secondary.main
      }
    },
    MuiTypography: {
      root: {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'red'
        }
      }
    }
  },
  // font overrides
  typography: {
    h1: {
      fontFamily: 'campton-light',
      fontSize: pxToRem(27.3),
      [mainTheme.breakpoints.up('sm')]: {
        fontSize: pxToRem(54.7)
      },
      [mainTheme.breakpoints.between('md', 'lg')]: {
        fontSize: '4vw'
      },
      [mainTheme.breakpoints.up('lg')]: {
        fontSize: pxToRem(54.7)
      }
    },
    h2: {
      fontFamily: 'campton-light',
      fontSize: pxToRem(27.3),
      [mainTheme.breakpoints.up('sm')]: {
        fontSize: pxToRem(40)
      },
      [mainTheme.breakpoints.up('lg')]: {
        fontSize: pxToRem(50)
      }
    },
    h3: {
      fontFamily: 'campton-book',
      fontSize: pxToRem(28)
    },
    h4: {
      fontFamily: 'nantes-bold',
      fontSize: pxToRem(27.3),
      [mainTheme.breakpoints.up('sm')]: {
        fontSize: pxToRem(40)
      },
      [mainTheme.breakpoints.up('lg')]: {
        fontSize: pxToRem(50)
      }
    },
    h5: {
      fontFamily: 'campton-book',
      fontSize: pxToRem(27),
      [mainTheme.breakpoints.down('sm')]: {
        fontSize: pxToRem(18)
      }
    },
    h6: {
      fontFamily: 'campton-medium',
      fontSize: pxToRem(14)
    },
    subtitle1: {
      fontFamily: 'campton-medium',
      fontSize: pxToRem(14)
    },
    subtitle2: {
      fontFamily: 'campton-book',
      fontSize: pxToRem(14),
      fontWeight: 'normal'
    },
    body1: {
      fontFamily: 'campton-book'
    },
    body2: {
      fontFamily: 'campton-medium',
      fontWeight: 500,
      fontSize: '1rem'
    },
    caption: {
      fontFamily: 'campton-book',
      fontSize: pxToRem(12)
    },
    button: {
      fontFamily: 'campton-medium'
    },
    overline: {
      // was 13.5px
      fontFamily: 'campton-book',
      fontSize: pxToRem(12)
    },
    overline2: {
      // this doesn't work
      // was 13.5px
      fontFamily: 'campton-medium',
      fontSize: pxToRem(14),
      textTransform: 'uppercase',
      fontWeight: 500
    },
    // to make immed switch to v2 and to get rid of console error <- this isn't really accurate anymore but this needs to be included or styling breaks. need to look into this more
    useNextVariants: true
  },
  // no idea what this is for but it breaks the login page form label if we move this up, leaving here for now until the tools page is revisited
  // MuiInputLabel: {
  //   root: {
  //     fontSize: "100px"
  //   }
  // }
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      carousel: 1150
    }
  }
})
