import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './FeaturedCarousel.scss'
import {
  useTheme,
  makeStyles
} from '@material-ui/core'
import toolsStyles from '../tools/ToolsStyles'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import sharedCardHalfStyles from '../sharedComponents/SharedCardStyles'
import { mainTheme } from '../assets/themes/MainTheme'

export default function FeaturedCarousel () {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...toolsStyles(theme),
    ...sharedCardHalfStyles(theme),
    o_container_featuredCreators: {
      margin: '0 auto',
      maxWidth: 1400,
      padding: theme.spacing(5, 1),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6.25, 1)
      }
    },
    carousel_container: {
      display: 'grid',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },
    title_container: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '50px',
      [theme.breakpoints.down('md')]: {
        paddingBottom: 20
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: 8
      }
    },
    m_card_content: {
      [theme.breakpoints.down('sm')]: {
        padding: 0
      },
      textAlign: 'start',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5)
      }
    },
    text: {
      display: 'grid',
      [theme.breakpoints.up('md')]: {
        height: 400
      }
    },
    media_container: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
        flexBasis: 0,
        objectFit: 'cover'
      },
      [theme.breakpoints.up('md')]: {
        height: 400,
        gridColumn: '2 / span 2',
        paddingRight: '40px'
      }
    },
    media: {
      objectFit: 'cover',
      height: '100%'
    }
  })
  const classes = useStyles()
  var items = [
    {
      name: 'Infuse Medical.',
      description:
        '"You can see the atrium, you can see the ventricles, you can cut through it. Yes, you could do that before in 3D, but there was something a lot more engaging with this that connected  me in more of an emotional way that was missing before.',
      description2: 'I\'m really excited to provide this to our audiences in the healthcare industry. I\'m really excited for physicians to see these, and sales reps to be able to present these antomical structures in a way that they\'ve never seen before. And I\'m excited to see their faces when they get blown away by this."',
      image: require('../assets/images/carousel2.png'),
      imageTitle: 'Heart',
      lastLine: '-Danny Harker | Creative Director | Infuse Medical'
    },
    {
      name: 'Jean-François Didelot.',
      description: '"It\'s really a game changer! Forget about complicated settings for 2 cameras, orbital or 360° shots. Just frame, light and shoot beautiful fine tuned 2D pictures, ingest them into Lightfield Studio and you have a quad file that can be visualized immediately on the LumePad. My pictures and video are now 3D — in your face, working great, without glasses."',
      description2: null,
      image: require('../assets/images/carousel1.png'),
      imageTitle: 'Jean-François Didelot',
      lastLine: '-Jean-François Didelot | Photographer'
    },
    {
      name: 'Playchemy.',
      description: '"I think 4V tablet games are going to be a thing. Making our games take advantage of 4V was easy. The results have inspired category 5 brainstorming on new game mechanics specifically designed for 4v. Its like I\'m having a dream playing my own game, and somehow the tablet display isn\'t a display. It is a window into the game world. A vivid fevered dream, familiar but more real."',
      image: require('../assets/images/carousel3.png'),
      imageTitle: 'Playchemy video game',
      lastLine: '-Ed Annunziata  | Founder | Playchemy'
    }
  ]
  return (
    <main style={{ backgroundColor: (theme.palette.type === 'dark' ? mainTheme.palette.primary.main : '#fafafa') }}>
      <div className={classes.o_layout_Home}>
        <div className={classes.o_container_featuredCreators}>
          <div className={classes.title_container}>
            <Typography variant="overline" align="center">featured creators</Typography>
          </div>
          <Carousel
            showStatus={false}
            useKeyboardArrows={true}
            autoPlay={true}
            stopOnHover={true}
            infiniteLoop={true}
            swipeable={true}
            emulateTouch={true}
            interval='5000'
            transitionTime='300'
          >
            {items.map((el, id) =>
              <>
                <div className={classes.carousel_container}>
                  <div className={classes.text}>
                    <CardContent className={classes.m_card_content}>
                      <Typography gutterBottom variant="h2">
                        {el.name}
                      </Typography>
                      <Typography variant="body2">
                        {el.description}
                      </Typography>
                      <br />
                      <Typography variant="body2">
                        {el.description2}
                      </Typography>
                      <br />
                      <Typography variant="body2">
                        {el.lastLine}
                      </Typography>
                    </CardContent>
                  </div>
                  <div className={classes.media_container}>
                    <img alt={el.imageTitle} src={el.image} className={classes.media}></img>
                  </div>
                </div>
              </>
            )}
          </Carousel>
        </div>
      </div>
    </main>
  )
}
