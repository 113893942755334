import React from 'react'

const SVG = ({
  style = {},
  fill = '#000',
  width = '100%',
  className = '',
  viewBox = '0 0 468 57'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <path d="M9.40628 22.7452H0V57.2937H30.0833V48.5614H9.40628V22.7452Z" fill="#1A1A1A"/>
      <path d="M56.5844 21.9841C45.7326 21.9841 38.2327 29.6169 38.2327 39.9561C38.2327 50.2741 45.7326 57.9281 56.7939 57.9281C63.2673 57.9281 68.7979 55.116 71.8775 52.0079L66.4935 46.1512C63.5606 48.8364 60.2925 49.8724 57.15 49.8724C52.6878 49.8724 48.6446 47.5678 47.3038 43.1911H73.7839C73.9305 42.0282 73.9934 40.8441 73.9934 39.8081C73.9934 29.5535 67.0382 21.9841 56.5844 21.9841ZM47.4505 36.0234C48.8331 31.8793 52.4574 29.6804 56.6682 29.6804C61.1723 29.6804 64.1262 32.8519 64.9223 36.0234H47.4505Z" fill="#1A1A1A"/>
      <path d="M95.6341 30.3781H86.7725V57.2938H95.6341V30.3781Z" fill="#1A1A1A"/>
      <path d="M91.1508 11.7929C87.4008 11.7929 84.2584 14.901 84.2584 18.7491C84.2584 20.1869 84.7403 21.5612 85.4944 22.7452H96.7442C97.5613 21.5824 98.0431 20.208 98.0431 18.7491C98.0222 14.901 94.9007 11.7929 91.1508 11.7929Z" fill="#1A1A1A"/>
      <path d="M135.312 26.0437C132.317 23.0836 128.902 21.9841 124.817 21.9841C115.892 21.9841 108.036 29.4266 108.036 39.9561C108.036 50.4856 115.871 57.8647 124.817 57.8647C128.902 57.8647 132.317 56.7652 135.312 53.8685V57.3149H143.964V22.7453H135.312V26.0437ZM135.312 44.1002C133.636 48.0329 130.347 49.7244 126.786 49.7244C120.983 49.7244 117.023 45.3266 117.023 39.9561C117.023 34.5857 120.983 30.1878 126.786 30.1878C130.347 30.1878 133.636 31.8793 135.312 35.812V44.1002Z" fill="#1A1A1A"/>
      <path d="M202.665 29.6592C198.684 29.6592 195.27 30.9912 192.567 33.7399C189.865 36.4674 188.461 39.8081 188.461 43.8253C188.461 47.8426 189.844 51.289 192.567 54.0165C195.29 56.7652 198.684 58.1606 202.665 58.1606C206.645 58.1606 209.997 56.7652 212.699 54.0165C215.402 51.289 216.806 47.8426 216.806 43.8253C216.806 39.8081 215.423 36.4885 212.699 33.7399C209.997 30.9912 206.645 29.6592 202.665 29.6592ZM209.871 51.437C207.965 53.4668 205.535 54.5239 202.665 54.5239C199.774 54.5239 197.364 53.4668 195.395 51.437C193.426 49.3438 192.462 46.7854 192.462 43.8042C192.462 40.8864 193.447 38.4549 195.395 36.404C197.364 34.3742 199.774 33.317 202.665 33.317C205.493 33.317 207.923 34.3742 209.871 36.404C211.841 38.4337 212.825 40.8864 212.825 43.8042C212.825 46.7854 211.841 49.3438 209.871 51.437Z" fill="#1A1A1A"/>
      <path d="M226.882 26.6779V30.3992H220.618V33.8456H226.882V57.3149H230.8V33.8456H240.373V30.378H230.8V26.6779C230.8 22.7664 233.23 20.208 236.98 20.208C238.195 20.208 239.64 20.3772 241.253 20.8L241.84 17.3536C239.996 16.8251 238.153 16.5291 236.351 16.5291C230.632 16.5291 226.882 20.5463 226.882 26.6779Z" fill="#1A1A1A"/>
      <path d="M261.177 54.4817C257.029 54.4817 255.185 52.4519 255.185 47.61V33.8033H265.283V30.3569H255.164V20.3983H251.247V30.3569H244.564V33.8033H251.247V47.61C251.247 54.6508 254.305 58.1606 260.485 58.1606C262.161 58.1606 264.13 57.8646 266.267 57.2303L265.639 53.7839C263.942 54.2491 262.496 54.4817 261.177 54.4817Z" fill="#1A1A1A"/>
      <path d="M164.788 22.7452H160.892V57.2937H184.083V53.7416H164.788V22.7452Z" fill="#1A1A1A"/>
      <path d="M276.291 25.3518C273.195 25.3518 270.579 27.7518 270.579 30.9198C270.579 34.1118 273.195 36.4878 276.291 36.4878C279.411 36.4878 282.003 34.1118 282.003 30.9198C282.003 27.7518 279.411 25.3518 276.291 25.3518ZM276.291 35.4798C273.819 35.4798 271.683 33.4878 271.683 30.9198C271.683 28.3518 273.819 26.3598 276.291 26.3598C278.763 26.3598 280.899 28.3518 280.899 30.9198C280.899 33.4878 278.763 35.4798 276.291 35.4798ZM278.595 29.9358C278.595 28.8558 277.731 28.1358 276.603 28.1358H274.371V33.4878H275.187V31.6878H276.243L277.707 33.4878H278.763L277.227 31.6158C278.043 31.3998 278.595 30.7998 278.595 29.9358ZM275.187 28.9038H276.579C277.251 28.9038 277.731 29.3118 277.731 29.9118C277.731 30.5358 277.251 30.9198 276.579 30.9198H275.187V28.9038Z" fill="#1A1A1A"/>
    </g>
    <path d="M297.032 7.5V63.5" stroke="#1A1A1A" stroke-width="2"/>
    <path d="M352.592 17.0354H320.893V21.0251H334.557V54.2H338.874V21.0251H352.592V17.0354ZM363.411 28.1847C355.76 28.1847 349.912 33.978 349.912 41.5203C349.912 49.1718 355.76 54.8558 363.411 54.8558C370.953 54.8558 376.856 49.1718 376.856 41.5203C376.856 33.978 370.953 28.1847 363.411 28.1847ZM363.411 51.03C358.055 51.03 354.065 46.8763 354.065 41.5203C354.065 36.2735 358.055 32.0105 363.411 32.0105C368.713 32.0105 372.648 36.2735 372.648 41.5203C372.648 46.8763 368.713 51.03 363.411 51.03ZM396.235 28.1847C388.584 28.1847 382.736 33.978 382.736 41.5203C382.736 49.1718 388.584 54.8558 396.235 54.8558C403.778 54.8558 409.68 49.1718 409.68 41.5203C409.68 33.978 403.778 28.1847 396.235 28.1847ZM396.235 51.03C390.879 51.03 386.89 46.8763 386.89 41.5203C386.89 36.2735 390.879 32.0105 396.235 32.0105C401.537 32.0105 405.472 36.2735 405.472 41.5203C405.472 46.8763 401.537 51.03 396.235 51.03ZM416.979 15.3957V54.2H421.132V15.3957H416.979ZM432.187 46.2205H428.306C429.071 52.1778 433.662 54.8558 440.111 54.8558C446.342 54.8558 450.824 52.0685 450.824 47.095C450.824 42.2308 446.943 40.5911 443.281 39.99L438.253 39.1701C434.81 38.569 433.553 37.2026 433.553 35.3444C433.553 33.0489 435.848 31.5733 439.674 31.5733C443.719 31.5733 446.397 33.0489 446.834 36.2188H450.714C450.222 30.2069 444.976 28.1847 439.674 28.1847C433.553 28.1847 429.399 31.0267 429.399 35.727C429.399 40.0446 432.788 41.9028 436.941 42.6133L442.68 43.5425C445.14 43.9797 446.834 45.2367 446.834 47.3682C446.834 49.937 444.156 51.4126 440.221 51.4126C435.575 51.4126 432.952 49.609 432.187 46.2205Z" fill="#1A1A1A"/>
    <defs>
      <clipPath id="clip0">
        <rect width="295.55" height="47.9535" fill="white" transform="translate(0 11)"/>
      </clipPath>
    </defs>  </svg>
)

export default SVG
