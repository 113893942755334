import React from 'react'
import {
  makeStyles,
  Grid,
  useTheme,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CardActions
} from '@material-ui/core'
import toolsStyles from '../tools/ToolsStyles'
import sharedCardStyles from '../sharedComponents/SharedCardStyles'

export default function CommunityCards () {
  const theme = useTheme()
  const items = [
    {
      title: 'Lume Pad.',
      summary: 'The Lume Pad Creators Edition is the first step. The world’s first fully 3D tablet opens up new possibilities for how to deliver your creations.',
      buttonLabel: 'learn more',
      href: 'https://www.lume-pad.com/',
      image: require('../assets/images/card1.png'),
      imageTitle: 'stillshot of lume pad',
      target: false
    },
    {
      title: 'Community.',
      summary: 'The LeiaLoft Forum directly connects you with Lightfield enthusiasts, fellow creators, and the teams at Leia.',
      buttonLabel: 'Visit forum',
      href: 'https://forums.leialoft.com',
      image: require('../assets/images/card2.png'),
      imageTitle: 'People gathered around microscope',
      target: '"target_blank"'
    },
    {
      title: 'Opportunities.',
      summary: 'Leverage 3D displays in ways you never thought possible. Find opportunities in  education, retail, hospitality, medical software, construction, design, and more.',
      buttonLabel: 'Opportunities',
      href: 'https://forums.leialoft.com/c/classifieds/55',
      image: require('../assets/images/card3.png'),
      imageTitle: 'Landscape old railroad tracks crossing over marsh',
      target: '"target_blank"'
    },
    {
      title: 'LeiaLoft® Store.',
      summary: 'Explore the LeiaLoft® store, dedicated to Lightfield apps and games. Experience a new world of fun, creativity and imagination.',
      buttonLabel: 'Learn more',
      href: 'https://www.lume-pad.com/leiaapp-info.php?page=loft',
      image: require('../assets/images/card4.png'),
      imageTitle: 'Sreenshot of LeiaLoft store opening page',
      target: false
    }
  ]

  const useStyles = makeStyles({
    ...toolsStyles(theme),
    ...sharedCardStyles(theme),
    root: {
      backgroundColor: (theme.palette.type === 'dark' && '#313131')
    },
    o_container_featured: {
      padding: theme.spacing(3, 0, 8, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(8, 1, 12, 1)
      }
    },
    cardContent_container: {
      display: 'grid',
      alignItems: 'start',
      padding: 16,
      gridTemplateRows: '3rem 8rem 3rem',
      [theme.breakpoints.up('md')]: {
        gridTemplateRows: '3rem 6rem 4rem',
        padding: 32
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateRows: '3rem 8rem 3rem',
        padding: 16
      }
    },
    button_container: {
      justifyContent: 'flex-end'
    },
    second_button: {
      [theme.breakpoints.between('xs', 'md')]: {
        gridRow: 2
      },
      [theme.breakpoints.up('lg')]: {
        gridColumn: 2
      }
    },
    text_header: {
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(8)
      }
    }
  })
  const classes = useStyles()
  return (
    <main style={{ backgroundColor: (theme.palette.type === 'dark' ? '#1a1a1a' : '#fafafa') }}>
      <div className={classes.o_layout_Home} >
        <div className={classes.o_container_featured}>
          <Typography className={classes.text_header} align="center" variant="h4">Unleash the magic of Lightfields.</Typography>
          <Grid container spacing={2} className={classes.o_grid_container}>
            {items.map((item, id) => {
              return (
                <Grid item xs={12} md={6} lg={3} key={id}>
                  <Card className={classes.root}>
                    <CardMedia
                      className={classes.media}
                      image={item.image}
                      title={item.imageTitle}
                      component="img"
                      height="260"
                    />
                    <CardContent className={classes.cardContent_container}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {item.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {item.summary}
                      </Typography>
                      <CardActions className={classes.button_container}>
                        <Button
                          variant="outlined"
                          color="inherit"
                          target={item.target}
                          href={item.href}
                        >
                          {item.buttonLabel}
                        </Button>
                      </CardActions>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </div>
      </div>
    </main>
  )
}
