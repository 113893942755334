import React from 'react'
import { NavLink } from 'react-router-dom'
import { Drawer, Typography, useTheme, useMediaQuery, Button, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../creatorAppBar/CreatorAppBarStyles'
import clsx from 'clsx'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

export default withStyles(styles)(
  ({ isShowing, setIsShowing, handleLogOut, topics, classes, handleThemeClick, icon }) => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    async function themeAndCloseClickHandler () {
      handleThemeClick()
      await setIsShowing(false)
    }
    const list = () => (
      <div
        className={clsx(
          classes.t_drawer
          // isLoggedIn && classes.t_drawer_authenticated
        )}
        role="presentation"
      >
        <div className={classes.a_close_icon}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="mode"
            onClick={themeAndCloseClickHandler}
            style={{ marginRight: '2px' }}
          >
            {icon}
          </IconButton>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="Close Menu"
            onClick={() => setIsShowing(false)}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>

        <div className={classes.o_link_container}>
          <Typography variant="subtitle2">
            <NavLink
              onClick={() => setIsShowing(false)}
              activeClassName={classes.a_NavLink_header_bold}
              className={classes.a_NavLink_header}
              to='/tools'>
              TOOLS
            </NavLink>
          </Typography>
          <Typography variant="subtitle2">
            <NavLink
              onClick={() => setIsShowing(false)}
              activeClassName={classes.a_NavLink_header_bold}
              className={classes.a_NavLink_header}
              to='/certification'>
              CERTIFICATION
            </NavLink>
          </Typography>
          <Typography
            className={classes.a_NavLink_header}
            variant="subtitle2"
          >
            <a
              onClick={() => setIsShowing(false)}
              style={{
                textDecoration: 'none',
                color: '#fff'
              }}
              href="https://forums.leialoft.com/"
              target="_blank"
              rel="noopener noreferrer">
                  FORUM
            </a>
          </Typography>
          <Typography
            className={classes.a_NavLink_header}
            variant="subtitle2"
          >
            <a
              onClick={() => setIsShowing(false)}
              style={{
                textDecoration: 'none',
                color: '#fff'
              }}
              href="https://developer.leialoft.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
                DEVELOPER PORTAL
            </a>
          </Typography>
          <Button
            className={classes.a_Private_LogOut_wrapper}
            variant="contained"
            component={NavLink}
            to="/get-certified"
            style={{ gridColumn: 3 }}
            onClick={() => setIsShowing(false)}
          >
            Get certified
          </Button>

          {/* {topics.map((topic, index) => (
            <>
              <Typography
                key={index}
                className={classes.a_NavLink_wrapper}
              >
                <NavLink
                  to={`${topic.to}/${topic.redirect}`}
                  activeClassName={classes.a_NavLink_header_bold}
                  className={classes.a_NavLink_header}
                >
                  {topic.name}
                </NavLink>
              </Typography>
              {pathname.includes(topic.to) && (
                <div className={classes.o_sublink_container}>
                  {topic.subtopic
                    .filter(
                      (role) => role.show === 'default' || role.show === user
                    )
                    .map((sub, subIndex) => (
                      <Typography
                        key={subIndex}
                        className={classes.a_NavLink_wrapper}
                      >
                        <NavLink
                          key={sub.to}
                          onClick={() => setIsShowing(false)}
                          to={`${topic.to}/${sub.to}`}
                          className={clsx(
                            classes.a_NavLink_header,
                            classes.a_NavLink_headerMenu
                          )}
                          activeClassName={classes.a_NavLink_header_active}
                        >
                          {sub.name}
                        </NavLink>
                      </Typography>
                    ))}
                </div>
              )}
            </>
          ))} */}
        </div>
      </div>
    )

    return (
      <Drawer
        anchor="left"
        open={isShowing && isSmallScreen}
        onClose={() => setIsShowing(false)}
      >
        {list()}
      </Drawer>
    )
  }
)
