import React from 'react'
import {
  makeStyles,
  useTheme,
  Card,
  CardMedia,
  Grid,
  CardContent
} from '@material-ui/core'
import clsx from 'clsx'
import sharedCardStyles from './SharedCardStyles'

export default function SharedCardHalfMedia ({
  image,
  imageTitle,
  height,
  component
}) {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...sharedCardStyles(theme),
    m_card_half_height: {
      height: 'fit-content',
      [theme.breakpoints.up('md')]: {
        height: '24rem'
      }
    },
    m_card_half_media: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0
      }
    }
  })
  const classes = useStyles()
  return (
    <Grid item xs={12} md={6}>
      <Card className={classes.card}>
        <CardContent
          className={clsx(
            classes.m_card_half_height,
            classes.m_card_half_media
          )}
          style={{ height }}
        >
          <CardMedia
            className={classes.m_cardMedia}
            component={component}
            image={image}
            title={imageTitle}
            autoPlay
            loop
            controls
            muted
          />
        </CardContent>
      </Card>
    </Grid>
  )
}
