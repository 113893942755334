import React from 'react'
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Button
} from '@material-ui/core'
import CertificationStyles from './CertificationStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'
import { NavLink } from 'react-router-dom'
import { mainTheme } from '../assets/themes/MainTheme'

const topics = [
  {
    name: 'CG Artists and Developers',
    list1: [
      'APK file (1.5GB max file size)',
      'Compatible with Android 10',
      '1.5GB max file size',
      'Use of UI elements and text (can be one in the same)',
      'Use of 2D to 3D switching',
      'Utilize depth - don’t be afraid to push this using positive and negative disparity. Sometimes you just want things to pop (positive disparity)'
    ],
    list2: [
      'Configure as 2x2 grid with min resolution of 1280x720',
      'Framerate of 30fps and a running time no more than 60 seconds',
      'File name format should be name_CompanyName_2x2.mp4 or', 'name_FirstnameLastname_2x2.mp4'
    ]
  },
  {
    name: 'Photographers',
    list1: [
      'Configure as 2x2 grid with min resolution of 1280x720',
      'Framerate of 30fps and a running time no more than 60 seconds',
      'File name format should be name_CompanyName_2x2.mp4 or name_FirstnameLastname_2x2.mp4'
    ]
  }
]

export default function SubmissionRequirements () {
  const cg = topics.find(({ name }) => name === 'CG Artists and Developers')
  const Photographers = topics.find(({ name }) => name === 'Photographers')
  const theme = useTheme()
  const useStyles = makeStyles({
    ...CertificationStyles(theme),
    root: {
      backgroundColor: (theme.palette.type === 'dark' && mainTheme.palette.primary.gray5),
      marginBottom: '12px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '24px'
      }
    },
    accordianSummary: {
      [theme.breakpoints.up('md')]: {
        padding: '12px 24px'
      }
    },
    o_layout_accordion: {
      width: 'auto',
      maxWidth: 1400,
      margin: '0 auto',
      padding: theme.spacing(2, 0),
      [theme.breakpoints.down('xs')]: {
        margin: '0 24px'
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 32px'
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8, 4)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8, 5)
      }
    },
    title: {
      textAlign: 'center',
      padding: theme.spacing(4, 0, 0),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8, 0, 0)
      }
    },
    a_NavLink_button: {
      color: 'white',
      textDecoration: 'none',
      fontSize: '0.875rem',
      textAlign: 'start'
    },
    m_list: {
      padding: '0 0 24px',
      [theme.breakpoints.up('sm')]: {
        width: '80%'
      }
    },
    m_accordianDetails: {
      display: 'grid',
      padding: '8px 16px 24px',
      [theme.breakpoints.up('md')]: {
        padding: '8px 16px 32px'
      }
    },
    m_ListItem: {
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    a_ListItemText: {
      overflowWrap: 'anywhere'
    },
    m_accordianButton_container: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        '& > :first-child': {
          marginBottom: 16
        }
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
        '& > :first-child': {
          marginRight: 32
        }
      }
    }
  })
  const classes = useStyles()
  return (
    <>
      <main style={{ backgroundColor: (theme.palette.type === 'dark' ? mainTheme.palette.primary.main : mainTheme.palette.primary.gray1) }}>
        <Typography className={classes.title} variant="h4">Submission requirements.</Typography>
        <div
          className={classes.o_layout_accordion}
        >
          <Accordion className={classes.root}>
            <AccordionSummary
              className={classes.accordianSummary}
              expandIcon={<ExpandMoreIcon fontSize="large"/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {topics[0].name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.m_accordianDetails}>
              <List dense className={classes.m_list}>
                <ListItem>
                  <ListItemText>
                    Applications:
                  </ListItemText>
                </ListItem>
                {cg.list1.map((el, id) =>
                  <ListItem className={classes.m_ListItem} key={id}>
                    <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }} />
                    <ListItemText className={classes.a_ListItemText} primary={el}/>
                  </ListItem>
                )}
              </List>
              <List dense className={classes.m_list}>
                <ListItem>
                  <ListItemText>
                    Video:
                  </ListItemText>
                </ListItem>
                {cg.list2.map((el, id) =>
                  <ListItem className={classes.m_ListItem} key={id}>
                    <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }} />
                    <ListItemText className={classes.a_ListItemText} primary={el}/>
                  </ListItem>
                )}
              </List>
              <div style={{ justifySelf: 'flex-end' }}>
                <FormControlLabel
                  style={{ alignSelf: 'flex-end' }}
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={ <Button variant="outlined" color="inherit" size="large" component={NavLink} to="/get-certified">
                      get certified
                  </Button>
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion >
          <Accordion className={classes.root}>
            <AccordionSummary
              className={classes.accordianSummary}
              expandIcon={<ExpandMoreIcon fontSize="large" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {topics[1].name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.m_accordianDetails}>
              <List dense >
                <ListItem>
                  <ListItemText>
                    Video:
                  </ListItemText>
                </ListItem>
                {Photographers.list1.map((el, id) =>
                  <ListItem className={classes.m_ListItem} key={id}>
                    <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }} />
                    <ListItemText className={classes.a_ListItemText} primary={el}/>
                  </ListItem>
                )}
              </List>
              <div style={{ display: 'grid', marginTop: 16 }}>
                <FormControlLabel
                  className={classes.m_accordianButton_container}
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <>
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="large"
                        href='https://docs.leialoft.com/developer/360-video-and-orbital-photography-training/orbital-photography-training'>
                      documentation
                      </Button>
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="large"
                        component={NavLink}
                        to="/get-certified">
                      get certified
                      </Button>
                    </>
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </main>

    </>
  )
}
