import React, { Fragment } from 'react'
import SharedCardHalfLg from '../sharedComponents/SharedCardHalfLg'
import SharedCardHalfMedia from '../sharedComponents/SharedCardHalfMedia'
import {
  makeStyles,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import toolsStyles from './ToolsStyles'

const SpotlightTool = [
  {
    title: 'Become a certified LeiaLoft® Creator.',
    summary:
      'Where Creators make high quality visual content for LitByLeia displays, earning certification within the community. Certified creators will be recommended to our industry partners in Retail, Luxury Hospitality, Medical, Automotive and Digital Media.',
    summary2:
      'The LeiaLoft® Certification program offers tutorials and documentation to empower new creators with an understanding of the nuances in creating Lightfield content. ',
    imageTitle: 'Drone still',
    toSubmitPath: true
  }
]

export default function CtaView () {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...toolsStyles(theme),
    o_container_Tools_CtaView: {
      display: 'grid',
      padding: theme.spacing(3, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(6, 1)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(10.5, 1)
      }
    }
  })
  const classes = useStyles()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  function resizeHeight () {
    if (isLargeScreen) {
      return '33rem'
    } else if (isMediumScreen) {
      return '38rem'
    } else {
      return null
    }
  }
  return (
    <main className={classes.o_layout_Home}>
      <div className={classes.o_container_Tools_CtaView}>
        <Grid container spacing={2} className={classes.o_grid_container}>
          {SpotlightTool.map(tool => {
            return (
              <Fragment key={tool.title}>
                <SharedCardHalfMedia
                  image={require('../assets/images/Games_Asset.png')}
                  imageTitle={tool.imageTitle}
                  height={resizeHeight()}
                  component="img"
                />
                <SharedCardHalfLg
                  height={resizeHeight()}
                  fontVariant="h2"
                  title={tool.title}
                  summary={tool.summary}
                  summary2={tool.summary2}
                  toSubmitPath='/get-certified'
                  toLearnMore='/certification'
                  purpleButton="submit content"
                  outlinedButton="learn more"
                />
              </Fragment>
            )
          })}
        </Grid>
      </div>
    </main>
  )
}
