import { mainTheme } from '../../../assets/themes/MainTheme'
import headerStyles from '../AppBarStyles'

export default theme => ({
  ...headerStyles(theme),
  // templates
  t_header_container_mobile: {
    justifySelf: 'end',
    gridColumn: '5 / 5',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  t_header_container_desktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gridColumn: '2 / 3',
      justifyContent: 'space-around',
      alignItems: 'center'
    }
  },
  t_header_container_desktop_creator: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gridColumn: '2',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  t_drawer: {
    position: 'relative',
    width: '90vw',
    background: '#1A1A1A',
    color: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  t_drawer_authenticated: {
    justifyContent: 'start'
  },
  // organisms
  o_link_container: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '325px',
      justifyContent: 'space-between',
      margin: '145px 0px'
    }
  },
  o_sublink_container: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  o_drawer_list: {
    display: 'flex',
    flexDirection: 'column'
  },
  // atoms
  a_drawer_paperAnchorLeft: {
    width: '80%',
    backgroundColor: mainTheme.palette.primary.main
  },
  a_btn_signUp: {
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      gridColumn: '2',
      justifySelf: 'end'
    }
  },
  a_Icon_account: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      color: mainTheme.palette.primary.main
    }
  },
  a_NavLink_header: {
    justifySelf: 'end',
    color: mainTheme.palette.primary.leiaLight,
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      color: theme.palette.text.primary,
      '&:hover': {
        color: (theme.palette.type === 'dark' ? mainTheme.palette.darkSecondary.hover : mainTheme.palette.secondary.main)
      }
    }
  },
  a_NavLink_header_bold: {
    fontWeight: 'bold'
  },
  a_NavLink_header_active: {
    textDecoration: 'underline',
    '&:hover': {
      color: (theme.palette.type === 'dark' ? mainTheme.palette.darkSecondary.hover : mainTheme.palette.secondary.main)
    }
  },
  a_NavLink_headerMenu: {
    [theme.breakpoints.up('md')]: {
      margin: '0 2.45rem'
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 1.45rem'
    },
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      fontFamily: 'campton-light'
    }
  },
  a_NavLink_responsive: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  a_NavLink_wrapper: {
    marginBottom: '5vh'
  },
  a_Private_LogIn_container: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridRow: '1',
      gridTemplateColumns: '80px 110px',
      justifyContent: 'flex-end',
      gridColumn: '4'
    }
  },
  a_Private_LogIn_wrapper: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '1.5rem',
      alignSelf: 'center',
      justifySelf: 'center'
    }
  },
  a_Private_LogOut_container: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '100px 50px',
      justifyContent: 'flex-end',
      gridColumn: '4'
    }
  },
  a_Private_LogOut_wrapper: {
    [theme.breakpoints.up('md')]: {
      justifySelf: 'end',
      alignSelf: 'center'
      // gridColumn: '1',
      // marginRight: '1rem'
    }
  },
  a_close_icon: {
    position: 'absolute',
    top: '1.25rem',
    right: '2rem',
    cursor: 'pointer'
  },
  a_themeSwitch_icon: {
    gridColumn: '4'
  },
  link: {
    textDecoration: 'none',
    color: (theme.palette.type === 'dark' ? '#fff' : '#1a1a1a'),
    '&:hover': {
      color: (theme.palette.type === 'dark' ? mainTheme.palette.darkSecondary.hover : mainTheme.palette.secondary.main)
    }
  }
})
