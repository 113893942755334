function pxToRem (value) {
  return `${value / 16}rem`
}

export default theme => ({
  root: {
    flexGrow: 1
  },
  a_text_font_nantes: {
    fontFamily: 'nantes-bold'
  },
  o_layout_Home: {
    width: 'auto',
    maxWidth: 1400,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.between('md', 'xl')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4)
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5)
    },
    [theme.breakpoints.up(1400 + theme.spacing(10))]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  o_section_container: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6, 1)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10.5, 1)
    }
  },
  o_grid_container: {
    justifyContent: 'center'
  },
  pBody: {
    [theme.breakpoints.up('md')]: {
      fontSize: pxToRem(18.2)
    }
  }
})
