import React from 'react'

const SVG = ({
  style = {},
  fill = '#000',
  width = '100%',
  className = '',
  viewBox = '0 0 468 57'
  // viewBox = '0 0 535 75'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <path d="M9.40628 15.7452H0V50.2937H30.0833V41.5614H9.40628V15.7452Z" fill="#FAFAFA" />
      <path d="M56.5844 14.9841C45.7326 14.9841 38.2327 22.6169 38.2327 32.9561C38.2327 43.2741 45.7326 50.9281 56.7939 50.9281C63.2673 50.9281 68.7979 48.116 71.8775 45.0079L66.4935 39.1512C63.5606 41.8364 60.2925 42.8724 57.15 42.8724C52.6878 42.8724 48.6446 40.5678 47.3038 36.1911H73.7839C73.9305 35.0282 73.9934 33.8441 73.9934 32.8081C73.9934 22.5535 67.0382 14.9841 56.5844 14.9841ZM47.4505 29.0234C48.8331 24.8793 52.4574 22.6804 56.6682 22.6804C61.1723 22.6804 64.1262 25.8519 64.9223 29.0234H47.4505Z" fill="#FAFAFA" />
      <path d="M95.6341 23.3781H86.7725V50.2938H95.6341V23.3781Z" fill="#FAFAFA" />
      <path d="M91.1508 4.79291C87.4008 4.79291 84.2584 7.901 84.2584 11.7491C84.2584 13.1869 84.7403 14.5612 85.4944 15.7452H96.7442C97.5613 14.5824 98.0431 13.208 98.0431 11.7491C98.0222 7.901 94.9007 4.79291 91.1508 4.79291Z" fill="#FAFAFA" />
      <path d="M135.312 19.0437C132.317 16.0836 128.902 14.9841 124.817 14.9841C115.892 14.9841 108.036 22.4266 108.036 32.9561C108.036 43.4856 115.871 50.8647 124.817 50.8647C128.902 50.8647 132.317 49.7652 135.312 46.8685V50.3149H143.964V15.7453H135.312V19.0437ZM135.312 37.1002C133.636 41.0329 130.347 42.7244 126.786 42.7244C120.983 42.7244 117.023 38.3266 117.023 32.9561C117.023 27.5857 120.983 23.1878 126.786 23.1878C130.347 23.1878 133.636 24.8793 135.312 28.812V37.1002Z" fill="#FAFAFA" />
      <path d="M202.665 22.6592C198.684 22.6592 195.27 23.9912 192.567 26.7399C189.865 29.4674 188.461 32.8081 188.461 36.8253C188.461 40.8426 189.844 44.289 192.567 47.0165C195.29 49.7652 198.684 51.1606 202.665 51.1606C206.645 51.1606 209.997 49.7652 212.699 47.0165C215.402 44.289 216.806 40.8426 216.806 36.8253C216.806 32.8081 215.423 29.4885 212.699 26.7399C209.997 23.9912 206.645 22.6592 202.665 22.6592ZM209.871 44.437C207.965 46.4668 205.535 47.5239 202.665 47.5239C199.774 47.5239 197.364 46.4668 195.395 44.437C193.426 42.3438 192.462 39.7854 192.462 36.8042C192.462 33.8864 193.447 31.4549 195.395 29.404C197.364 27.3742 199.774 26.317 202.665 26.317C205.493 26.317 207.923 27.3742 209.871 29.404C211.841 31.4337 212.825 33.8864 212.825 36.8042C212.825 39.7854 211.841 42.3438 209.871 44.437Z" fill="#FAFAFA" />
      <path d="M226.882 19.6779V23.3992H220.618V26.8456H226.882V50.3149H230.8V26.8456H240.373V23.378H230.8V19.6779C230.8 15.7664 233.23 13.208 236.98 13.208C238.195 13.208 239.64 13.3772 241.253 13.8L241.84 10.3536C239.996 9.82506 238.153 9.52905 236.351 9.52905C230.632 9.52905 226.882 13.5463 226.882 19.6779Z" fill="#FAFAFA" />
      <path d="M261.177 47.4817C257.029 47.4817 255.185 45.4519 255.185 40.61V26.8033H265.283V23.3569H255.164V13.3983H251.247V23.3569H244.564V26.8033H251.247V40.61C251.247 47.6508 254.305 51.1606 260.485 51.1606C262.161 51.1606 264.13 50.8646 266.267 50.2303L265.639 46.7839C263.942 47.2491 262.496 47.4817 261.177 47.4817Z" fill="#FAFAFA" />
      <path d="M164.788 15.7452H160.892V50.2937H184.083V46.7416H164.788V15.7452Z" fill="#FAFAFA" />
      <path d="M276.291 18.3518C273.195 18.3518 270.579 20.7518 270.579 23.9198C270.579 27.1118 273.195 29.4878 276.291 29.4878C279.411 29.4878 282.003 27.1118 282.003 23.9198C282.003 20.7518 279.411 18.3518 276.291 18.3518ZM276.291 28.4798C273.819 28.4798 271.683 26.4878 271.683 23.9198C271.683 21.3518 273.819 19.3598 276.291 19.3598C278.763 19.3598 280.899 21.3518 280.899 23.9198C280.899 26.4878 278.763 28.4798 276.291 28.4798ZM278.595 22.9358C278.595 21.8558 277.731 21.1358 276.603 21.1358H274.371V26.4878H275.187V24.6878H276.243L277.707 26.4878H278.763L277.227 24.6158C278.043 24.3998 278.595 23.7998 278.595 22.9358ZM275.187 21.9038H276.579C277.251 21.9038 277.731 22.3118 277.731 22.9118C277.731 23.5358 277.251 23.9198 276.579 23.9198H275.187V21.9038Z" fill="#FAFAFA" />
    </g>
    <path d="M297.032 0.5V56.5" stroke="#FAFAFA" stroke-width="2" />
    <path d="M352.592 10.0354H320.893V14.0251H334.557V47.2H338.874V14.0251H352.592V10.0354ZM363.411 21.1847C355.76 21.1847 349.912 26.978 349.912 34.5203C349.912 42.1718 355.76 47.8558 363.411 47.8558C370.953 47.8558 376.856 42.1718 376.856 34.5203C376.856 26.978 370.953 21.1847 363.411 21.1847ZM363.411 44.03C358.055 44.03 354.065 39.8763 354.065 34.5203C354.065 29.2735 358.055 25.0105 363.411 25.0105C368.713 25.0105 372.648 29.2735 372.648 34.5203C372.648 39.8763 368.713 44.03 363.411 44.03ZM396.235 21.1847C388.584 21.1847 382.736 26.978 382.736 34.5203C382.736 42.1718 388.584 47.8558 396.235 47.8558C403.778 47.8558 409.68 42.1718 409.68 34.5203C409.68 26.978 403.778 21.1847 396.235 21.1847ZM396.235 44.03C390.879 44.03 386.89 39.8763 386.89 34.5203C386.89 29.2735 390.879 25.0105 396.235 25.0105C401.537 25.0105 405.472 29.2735 405.472 34.5203C405.472 39.8763 401.537 44.03 396.235 44.03ZM416.979 8.39573V47.2H421.132V8.39573H416.979ZM432.187 39.2205H428.306C429.071 45.1778 433.662 47.8558 440.111 47.8558C446.342 47.8558 450.824 45.0685 450.824 40.095C450.824 35.2308 446.943 33.5911 443.281 32.99L438.253 32.1701C434.81 31.569 433.553 30.2026 433.553 28.3444C433.553 26.0489 435.848 24.5733 439.674 24.5733C443.719 24.5733 446.397 26.0489 446.834 29.2188H450.714C450.222 23.2069 444.976 21.1847 439.674 21.1847C433.553 21.1847 429.399 24.0267 429.399 28.727C429.399 33.0446 432.788 34.9028 436.941 35.6133L442.68 36.5425C445.14 36.9797 446.834 38.2367 446.834 40.3682C446.834 42.937 444.156 44.4126 440.221 44.4126C435.575 44.4126 432.952 42.609 432.187 39.2205Z" fill="#FAFAFA" />
    <defs>
      <clipPath id="clip0">
        <rect width="295.55" height="47.9535" fill="white" transform="translate(0 4)" />
      </clipPath>
    </defs>  </svg>
)

export default SVG
