import React from 'react'

const SVG = ({
  style = {},
  width = '140',
  height = '23',
  viewBox = '0 0 140 23',
  fill = 'none'
}) => (
  // eslint-disable-next-line
    <a href='/' >
    <svg
      style={style}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath="url(#clip0)">
        <path d="M4.67848 9.8783H0V27.1693H14.9628V22.7989H4.67848V9.8783Z" fill="#FAFAFA"/>
        <path d="M28.1438 9.49744C22.7464 9.49744 19.0161 13.3175 19.0161 18.4921C19.0161 23.6562 22.7464 27.4869 28.248 27.4869C31.4677 27.4869 34.2186 26.0794 35.7503 24.5239L33.0724 21.5927C31.6136 22.9366 29.9881 23.4551 28.4252 23.4551C26.2058 23.4551 24.1947 22.3017 23.5279 20.1112H36.6985C36.7714 19.5292 36.8027 18.9366 36.8027 18.4181C36.8027 13.2858 33.3433 9.49744 28.1438 9.49744ZM23.6008 16.5239C24.2885 14.4498 26.0911 13.3493 28.1855 13.3493C30.4258 13.3493 31.895 14.9366 32.2909 16.5239H23.6008Z" fill="#FAFAFA"/>
        <path d="M47.5663 13.6985H43.1587V27.1694H47.5663V13.6985Z" fill="#FAFAFA"/>
        <path d="M45.3363 4.39685C43.4712 4.39685 41.9082 5.95241 41.9082 7.87833C41.9082 8.59791 42.1479 9.28574 42.523 9.87833H48.1184C48.5247 9.29632 48.7644 8.60849 48.7644 7.87833C48.754 5.95241 47.2014 4.39685 45.3363 4.39685Z" fill="#FAFAFA"/>
        <path d="M67.3014 11.5292C65.8114 10.0477 64.113 9.49744 62.0811 9.49744C57.6423 9.49744 53.7349 13.2223 53.7349 18.4921C53.7349 23.762 57.6319 27.4551 62.0811 27.4551C64.113 27.4551 65.8114 26.9048 67.3014 25.4551V27.18H71.6048V9.87839H67.3014V11.5292ZM67.3014 20.5662C66.4678 22.5345 64.8319 23.381 63.0606 23.381C60.1743 23.381 58.2049 21.18 58.2049 18.4921C58.2049 15.8043 60.1743 13.6033 63.0606 13.6033C64.8319 13.6033 66.4678 14.4498 67.3014 16.4181V20.5662Z" fill="#FAFAFA"/>
        <path d="M100.801 13.3386C98.8212 13.3386 97.1228 14.0053 95.7786 15.381C94.4345 16.746 93.7363 18.418 93.7363 20.4286C93.7363 22.4392 94.424 24.164 95.7786 25.5291C97.1332 26.9048 98.8212 27.6032 100.801 27.6032C102.781 27.6032 104.448 26.9048 105.792 25.5291C107.136 24.164 107.834 22.4392 107.834 20.4286C107.834 18.418 107.147 16.7566 105.792 15.381C104.448 14.0053 102.781 13.3386 100.801 13.3386ZM104.385 24.2381C103.437 25.254 102.228 25.7831 100.801 25.7831C99.363 25.7831 98.1647 25.254 97.1853 24.2381C96.2058 23.1905 95.7265 21.9101 95.7265 20.418C95.7265 18.9577 96.2162 17.7407 97.1853 16.7143C98.1647 15.6984 99.363 15.1693 100.801 15.1693C102.208 15.1693 103.416 15.6984 104.385 16.7143C105.365 17.7302 105.855 18.9577 105.855 20.418C105.855 21.9101 105.365 23.1905 104.385 24.2381Z" fill="#FAFAFA"/>
        <path d="M112.846 11.8466V13.709H109.73V15.4339H112.846V27.1799H114.794V15.4339H119.556V13.6984H114.794V11.8466C114.794 9.8889 116.003 8.60848 117.868 8.60848C118.473 8.60848 119.192 8.69314 119.994 8.90478L120.286 7.17991C119.369 6.91536 118.452 6.76721 117.556 6.76721C114.711 6.76721 112.846 8.77779 112.846 11.8466Z" fill="#FAFAFA"/>
        <path d="M129.903 25.7619C127.84 25.7619 126.923 24.7461 126.923 22.3228V15.4127H131.946V13.6879H126.913V8.70374H124.965V13.6879H121.641V15.4127H124.965V22.3228C124.965 25.8466 126.486 27.6032 129.56 27.6032C130.393 27.6032 131.373 27.4551 132.436 27.1376L132.123 25.4127C131.279 25.6455 130.56 25.7619 129.903 25.7619Z" fill="#FAFAFA"/>
        <path d="M81.962 9.8783H80.0239V27.1693H91.5586V25.3915H81.962V9.8783Z" fill="#FAFAFA"/>
        <path d="M140.775 7.344C137.679 7.344 135.063 9.744 135.063 12.912C135.063 16.104 137.679 18.48 140.775 18.48C143.895 18.48 146.487 16.104 146.487 12.912C146.487 9.744 143.895 7.344 140.775 7.344ZM140.775 17.472C138.303 17.472 136.167 15.48 136.167 12.912C136.167 10.344 138.303 8.352 140.775 8.352C143.247 8.352 145.383 10.344 145.383 12.912C145.383 15.48 143.247 17.472 140.775 17.472ZM143.079 11.928C143.079 10.848 142.215 10.128 141.087 10.128H138.855V15.48H139.671V13.68H140.727L142.191 15.48H143.247L141.711 13.608C142.527 13.392 143.079 12.792 143.079 11.928ZM139.671 10.896H141.063C141.735 10.896 142.215 11.304 142.215 11.904C142.215 12.528 141.735 12.912 141.063 12.912H139.671V10.896Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="147" height="31" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </a>
)

export default SVG
