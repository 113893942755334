export const creatorTopics = [
  {
    name: 'leialoft®',
    subtopic: [
      {
        name: 'Tools',
        to: '/tools',
        href: false
      },
      {
        name: 'Certification',
        to: '/certification',
        href: false
      },
      {
        name: 'Get Certified',
        to: '/get-certified',
        href: false
      },
      {
        name: 'Developer Portal',
        to: false,
        href: 'https://developer.leialoft.com/main/documentation'
      }
    ]
  },
  {
    name: 'Leia Inc',
    subtopic: [
      {
        name: 'Leia Inc',
        to: false,
        href: 'https://www.leiainc.com/'
      },
      {
        name: 'Lume Pad',
        to: false,
        href: 'https://www.lume-pad.com'
      },
      {
        name: 'Forum',
        to: false,
        href: 'https://forums.leialoft.com'
      },
      {
        name: 'Contact us',
        to: false,
        href: 'https://www.leiainc.com/contact-us'
      }
    ]
  },
  {
    name: 'Legal',
    subtopic: [
      {
        name: 'Privacy Policy',
        to: '/privacy-policy',
        href: false
      },
      {
        name: 'Terms Of Service',
        to: 'terms-of-service',
        href: false

      },
      {
        name: 'License Agreement',
        to: 'license-agreement',
        href: false

      }
    ]
  }
]
