import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import SharedCardFull from '../sharedComponents/SharedCardFull'
import CertificationStyles from './CertificationStyles'
import { mainTheme } from '../assets/themes/MainTheme'

const content = [
  {
    label: 'leialoft® certification',
    title: 'Benefits.',
    summary: 'Leia Creators have access to several benefits including our recommendation with industry partners looking to develop ground breaking Lightfield content.',
    listItemText: [
      'Inclusion in the LeiaLoft® Certified Creators list, which is recommended to enterprise partners in Retail, Luxury Hospitality, Automotive, Healthcare and Digital Media.',
      'Promotion via the LeiaLoft® website.',
      'Access to the LeiaLoft® Certification mark, which can be leveraged in marketing materials.',
      'Rapid approval of applications submitted to the LeiaLoft® store. '
    ],
    link: 'https://developer.leialoft.com/'
  }
]

export default function Benefits () {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...CertificationStyles(theme)
  })
  const classes = useStyles()
  return (
    <main style={{ backgroundColor: (theme.palette.type === 'dark' ? mainTheme.palette.primary.gray3 : '#fff') }}>
      <div className={classes.o_layout_Home}>
        <div className={classes.o_section_container}>
          {content.map(tool =>
            <SharedCardFull
              key={tool.title}
              label={tool.label}
              title={tool.title}
              summary={tool.summary}
              link={tool.link}
              theme={theme}
              listItemText={tool.listItemText[0]}
              listItemText1={tool.listItemText[1]}
              listItemText2={tool.listItemText[2]}
              listItemText3={tool.listItemText[3]}
              display={ 'flex' }
              target="_blank"
              backgroundColor={(theme.palette.type === 'dark' ? mainTheme.palette.primary.gray5 : mainTheme.palette.primary.gray1)}
            />
          )}
        </div>
      </div>
    </main>
  )
}
