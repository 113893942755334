import React from 'react'
import clsx from 'clsx'
import {
  Card,
  CardActions,
  Button,
  Grid,
  CardContent,
  Typography,
  makeStyles,
  useTheme,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
// import { NavLink } from 'react-router-dom'
import sharedCardStyles from './SharedCardStyles'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'

export default function SharedCardFull ({
  label,
  title,
  summary,
  link,
  buttonVariant,
  buttonLabel,
  listItemText,
  listItemText1,
  listItemText2,
  listItemText3,
  style,
  summary2,
  display,
  backgroundColor,
  to,
  component,
  target,
  color
}) {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...sharedCardStyles(theme),
    m_card_full: {
      height: 'fit-content',
      alignItems: 'end',
      gridTemplateRows: 'auto 3rem',
      gridGap: '2rem 7rem',
      [theme.breakpoints.up('md')]: {
        gridGap: '0 7rem'
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2)
      },
      // [theme.breakpoints.up('md')]: {
      //   padding: theme.spacing(2, 5, 5)
      // },
      // [theme.breakpoints.up('lg')]: {
      //   padding: theme.spacing(2, 5, 5)
      // },
      // [theme.breakpoints.up('xl')]: {
      //   padding: theme.spacing(0, 5, 5)
      // }
    },
    m_cardFull_content_container: {

    },
    m_cardAction_btn_container: {
      gridColumn: '1',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('md')]: {
        gridColumn: '2/ span 1',
        gridRow: '2/ span 2'
      }
    },
    m_ListItem: {
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    }
  })
  const classes = useStyles()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card style={{ style, backgroundColor }}>
          <CardContent
            className={clsx(
              classes.o_card_layout,
              classes.m_card_full
            )}
            style={{ display }}
          >
            <div className={classes.m_cardFull_content_container}>
              <Typography
                variant="overline"
                gutterBottom
              >
                {label}
              </Typography>
              <Typography
                className={classes.a_text_font_nantes}
                variant="h2"
                gutterBottom
              >
                {title}
              </Typography>
              <Typography
                className={classes.pBody}
                variant="body1"
                gutterBottom
              >
                {summary}
              </Typography>
              {
                listItemText &&
                  <List dense >
                    <ListItem className={classes.m_ListItem}>
                      <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }} />
                      <ListItemText primary={listItemText} />
                    </ListItem>
                    <ListItem className={classes.m_ListItem}>
                      <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }}/>
                      <ListItemText primary={listItemText1} />
                    </ListItem>
                    <ListItem className={classes.m_ListItem}>
                      <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }}/>
                      <ListItemText primary={listItemText2} />
                    </ListItem>
                    <ListItem className={classes.m_ListItem}>
                      <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }}/>
                      <ListItemText primary={listItemText3} />
                    </ListItem>
                  </List>
              }
              <Typography
                className={classes.pBody}
                variant="body1"
                gutterBottom
              >
                {summary2}
              </Typography>
            </div>
            {
              buttonLabel &&
              <CardActions className={classes.m_cardAction_btn_container}>
                <Button
                  size="large"
                  variant={buttonVariant}
                  color={color}
                  target={target}
                  href={link}
                  component={component}
                  to={to}
                >
                  {buttonLabel}
                </Button>
              </CardActions>
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
