import React from 'react'
import { mainTheme } from '../../assets/themes/MainTheme'
import { NavLink } from 'react-router-dom'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  o_footer_container: {
    backgroundColor: '#f5f5f5',
    paddingBottom: '5em',
    paddingTop: '2rem',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '6em'
    }
  },
  m_footerList_container: {
    paddingLeft: '2.45em',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '4em'
    },
    [theme.breakpoints.down('sm')]: {
      '&  :last-child': {
        paddingBottom: '2rem'
      }
    }
  },
  a_NavLink_footer_label: {
    fontFamily: 'campton-medium',
    marginBottom: '1em'
  },
  a_NavLink_footer: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      color: (theme.palette.type === 'dark' ? mainTheme.palette.darkSecondary.hover : mainTheme.palette.secondary.main)
    }
  },
  a_active_NavLink_footer: {
    textDecoration: 'underline',
    marginBottom: '1em',
    '&:hover': {
      color: (theme.palette.type === 'dark' ? mainTheme.palette.darkSecondary.hover : mainTheme.palette.secondary.main)
    }
  },
  a_text_copyright_wrapper: {
    paddingTop: '2rem'
  },
  a_text_NavLink_footerList: {
    marginBottom: '1em'
  }
}))

export const SharedGridList = ({ to, subtopicName, href }) => {
  const classes = useStyles()
  return (
    <Typography
      variant="overline"
    >{ to &&
      <NavLink
        to={to}
        className={classes.a_NavLink_footer}
        activeClassName={classes.a_active_NavLink_footer}
      >
        {subtopicName}
      </NavLink>
      }
      { href &&
        <a href={href} className={classes.a_NavLink_footer}>{subtopicName}</a>
      }
    </Typography>
  )
}

export const StyledNavLink = ({ label, onClick }) => {
  const classes = useStyles()
  return (
    <Typography
      variant="subtitle2"
      className={classes.a_text_NavLink_footerList}
    >
      <NavLink
        onClick={onClick}
        to="#"
        className={classes.a_NavLink_footer}
      >
        {label}
      </NavLink>
    </Typography>
  )
}

export const StyledTypography = ({ topicName }) => {
  const classes = useStyles()
  return (
    <Typography
      variant="button"
      className={classes.a_text_NavLink_footerList}
    >
      {topicName}
    </Typography>
  )
}
