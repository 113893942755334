// 
import React from 'react'
import {
  useTheme,
  makeStyles,
  Grid,
  Typography,
  Button
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import Image from '../assets/images/Hero_Light_Texture.png'
import LightImage from '../assets/images/LIGHT_MODE_LEIA_LOFT_TEXTURE_01_RGB.png'
import toolsStyles from '../tools/ToolsStyles'

export default function HeroView () {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...toolsStyles(theme),
    o_container_Hero: {
      alignItems: 'center',
      margin: '0 auto',
      padding: theme.spacing(8.25, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(9, 0)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(13, 0)
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(17, 0)
      },
      backgroundImage: theme.palette.type === 'dark'
        ? 'url(' + Image + ')'
        : 'url(' + LightImage + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left',
      backgroundSize: 'cover'
    },
    m_container_Logo: {
      // maxWidth: 1280,
      margin: '0 auto',
      display: 'flex',
      height: '100%',
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 4)
      }
    },
    m_button_container: {
      display: 'grid',
      gridGap: '1rem',
      marginTop: '2rem',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'auto 13rem 13rem',
        gridGap: '2rem'
      }
    },
    a_text_subtitle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      fontFamily: 'campton-book',
      marginBottom: '0.89rem'
    },
    a_text_Hero: {
      padding: theme.spacing(2, 0, 0)
    },
    a_firstButton: {
      [theme.breakpoints.up('sm')]: {
        gridColumn: 2
      }
    },
    a_secondButton: {
      [theme.breakpoints.up('sm')]: {
        gridColumn: 3
      }
    }
  })
  const classes = useStyles()
  return (
    <main>
      <div className={classes.o_container_Hero}>
        <div className={classes.o_layout_Home}>
          <div className={classes.m_container_Logo}>
            <Grid container justify="flex-start">
              <Grid item sm={11} md={10} lg={8}>
                <Typography className={classes.a_text_subtitle}>
                LeiaLoft® certification
                </Typography>
                <Typography variant="h4" gutterBottom>
                Get recognized.
                </Typography>
                <Typography>
                Where Creators make high quality visual content for LitByLeia displays, earning certification within the community. Certified creators will be shared with organizations seeking content partners in Retail, Luxury Hospitality, Automotive, Healthcare and Digital Media. The LeiaLoft® Certification program offers tutorials and documentation to empower new Creators with an understanding of the nuances in creating Lightfield content.
                </Typography>
              </Grid>
              <Grid
                container lg={12}
                className={classes.m_button_container}
              >
                <Button
                  component={NavLink}
                  to='/get-certified'
                  variant="outlined"
                  size="large"
                  color="inherit"
                  className={classes.a_secondButton}
                >
                Get certified
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </main>
  )
}
