import React from 'react'

const SVG = ({
  style = {},
  width = '140',
  height = '23',
  viewBox = '0 0 140 23',
  fill = 'none'
}) => (
  // eslint-disable-next-line
    <a href='/' >
    <svg
      style={style}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns='http://www.w3.org/2000/svg'>
      <g clip-path="url(#clip0)">
        <path d="M4.67848 5.48145H0V22.7725H14.9628V18.4021H4.67848V5.48145Z" fill="#1A1A1A"/>
        <path d="M28.1438 5.10059C22.7464 5.10059 19.0161 8.92069 19.0161 14.0953C19.0161 19.2593 22.7464 23.09 28.248 23.09C31.4677 23.09 34.2186 21.6826 35.7503 20.127L33.0724 17.1958C31.6136 18.5397 29.9881 19.0583 28.4252 19.0583C26.2058 19.0583 24.1947 17.9048 23.5279 15.7143H36.6985C36.7714 15.1323 36.8027 14.5397 36.8027 14.0212C36.8027 8.88895 33.3433 5.10059 28.1438 5.10059ZM23.6008 12.127C24.2885 10.053 26.0911 8.95244 28.1855 8.95244C30.4258 8.95244 31.895 10.5397 32.2909 12.127H23.6008Z" fill="#1A1A1A"/>
        <path d="M47.5663 9.30164H43.1587V22.7725H47.5663V9.30164Z" fill="#1A1A1A"/>
        <path d="M45.3363 0C43.4712 0 41.9082 1.55556 41.9082 3.48148C41.9082 4.20106 42.1479 4.88889 42.523 5.48148H48.1184C48.5247 4.89947 48.7644 4.21164 48.7644 3.48148C48.754 1.55556 47.2014 0 45.3363 0Z" fill="#1A1A1A"/>
        <path d="M67.3014 7.13233C65.8114 5.65085 64.113 5.10059 62.0811 5.10059C57.6423 5.10059 53.7349 8.82545 53.7349 14.0953C53.7349 19.3651 57.6319 23.0583 62.0811 23.0583C64.113 23.0583 65.8114 22.508 67.3014 21.0583V22.7831H71.6048V5.48154H67.3014V7.13233ZM67.3014 16.1694C66.4678 18.1376 64.8319 18.9842 63.0606 18.9842C60.1743 18.9842 58.2049 16.7831 58.2049 14.0953C58.2049 11.4075 60.1743 9.20641 63.0606 9.20641C64.8319 9.20641 66.4678 10.053 67.3014 12.0212V16.1694Z" fill="#1A1A1A"/>
        <path d="M100.801 8.94177C98.8212 8.94177 97.1228 9.60844 95.7786 10.9841C94.4345 12.3492 93.7363 14.0211 93.7363 16.0317C93.7363 18.0423 94.424 19.7672 95.7786 21.1322C97.1332 22.5079 98.8212 23.2063 100.801 23.2063C102.781 23.2063 104.448 22.5079 105.792 21.1322C107.136 19.7672 107.834 18.0423 107.834 16.0317C107.834 14.0211 107.147 12.3598 105.792 10.9841C104.448 9.60844 102.781 8.94177 100.801 8.94177ZM104.385 19.8412C103.437 20.8571 102.228 21.3862 100.801 21.3862C99.363 21.3862 98.1647 20.8571 97.1853 19.8412C96.2058 18.7936 95.7265 17.5132 95.7265 16.0211C95.7265 14.5608 96.2162 13.3439 97.1853 12.3174C98.1647 11.3016 99.363 10.7725 100.801 10.7725C102.208 10.7725 103.416 11.3016 104.385 12.3174C105.365 13.3333 105.855 14.5608 105.855 16.0211C105.855 17.5132 105.365 18.7936 104.385 19.8412Z" fill="#1A1A1A"/>
        <path d="M112.846 7.44973V9.31216H109.73V11.037H112.846V22.7831H114.794V11.037H119.556V9.30158H114.794V7.44973C114.794 5.49205 116.003 4.21163 117.868 4.21163C118.473 4.21163 119.192 4.29629 119.994 4.50793L120.286 2.78306C119.369 2.51851 118.452 2.37036 117.556 2.37036C114.711 2.37036 112.846 4.38094 112.846 7.44973Z" fill="#1A1A1A"/>
        <path d="M129.903 21.3651C127.84 21.3651 126.923 20.3492 126.923 17.9259V11.0159H131.946V9.29101H126.913V4.30688H124.965V9.29101H121.641V11.0159H124.965V17.9259C124.965 21.4497 126.486 23.2064 129.56 23.2064C130.393 23.2064 131.373 23.0582 132.436 22.7408L132.123 21.0159C131.279 21.2487 130.56 21.3651 129.903 21.3651Z" fill="#1A1A1A"/>
        <path d="M81.962 5.48145H80.0239V22.7725H91.5586V20.9947H81.962V5.48145Z" fill="#1A1A1A"/>
        <path d="M140.775 4.344C137.679 4.344 135.063 6.744 135.063 9.912C135.063 13.104 137.679 15.48 140.775 15.48C143.895 15.48 146.487 13.104 146.487 9.912C146.487 6.744 143.895 4.344 140.775 4.344ZM140.775 14.472C138.303 14.472 136.167 12.48 136.167 9.912C136.167 7.344 138.303 5.352 140.775 5.352C143.247 5.352 145.383 7.344 145.383 9.912C145.383 12.48 143.247 14.472 140.775 14.472ZM143.079 8.928C143.079 7.848 142.215 7.128 141.087 7.128H138.855V12.48H139.671V10.68H140.727L142.191 12.48H143.247L141.711 10.608C142.527 10.392 143.079 9.792 143.079 8.928ZM139.671 7.896H141.063C141.735 7.896 142.215 8.304 142.215 8.904C142.215 9.528 141.735 9.912 141.063 9.912H139.671V7.896Z" fill="#1A1A1A"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="147" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </a>
)

export default SVG
