import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles, AppBar, Toolbar, CssBaseline } from '@material-ui/core/'
import styles from './AppBarStyles'
import Logo from '../../assets/logo/logo'
// import PrivateAppBarContainer from '../../navigation/header/privateAppBar/PrivateAppBarContainer'
import CreatorAppBarContainer from '../header/creatorAppBar/CreatorAppBarContainer'

const DefaultAppBar = ({ classes, user, topics, handleLogOut, handleMobileMenuClick, handleThemeClick, icon, theme }) => {
  const getThemeLogo = () => {
    if (theme === true) {
      return <Logo name="ll-white" />
    } else {
      return <Logo name="ll-black" />
    }
  }

  // role logic putting back after creator console goes live
  // const getLogo = () => {
  //   if (user === 'admin') {
  //     return <Logo name="leia-loft-admin" />
  //   } else if (user === 'developer') {
  //     return <Logo name="leia-loft-developer" />
  //   } else {
  //     return <Logo name="leia-loft" />
  //   }
  // }
  return (
    <Fragment>
      <CssBaseline />
      <AppBar
        elevation={0}
        color="default"
        className={classes.t_appBar_container}
      >
        <Toolbar
          className={classes.m_toolbar_container_creator} // just for creator
          // className={classes.m_toolbar_container} //** COMMENTING OUT FOR LEIALOGIN 2.0 */
        >
          <div
            className={classes.a_logo_header}
          >
            {getThemeLogo()}
          </div>
          <CreatorAppBarContainer
            topics={topics}
            handleMobileMenuClick={handleMobileMenuClick}
            handleLogOut={handleLogOut}
            user={user}
            handleThemeClick={handleThemeClick}
            icon={icon}
          />

          {/* NEED TO COMMENT OUT PRIVATE APP BAR FOR CREATOR'S EDITION TEMPORARILY */}
          {/* <PrivateAppBarContainer
             topics={topics}
             handleMobileMenuClick={handleMobileMenuClick}
             handleLogOut={handleLogOut}
             user={user}
           /> */}

        </Toolbar>
      </AppBar>
    </Fragment>
  )
}

DefaultAppBar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DefaultAppBar)
