import React, { Fragment } from 'react'
import { makeStyles, Typography, CssBaseline, Container, Paper, useTheme } from '@material-ui/core'

const PrivacyPolicy = () => {
  const theme = useTheme()
  const useStyles = makeStyles({
    o_terms_container: {
      [theme.breakpoints.up('xs')]: {
        paddingBottom: 16
      },
      [theme.breakpoints.up('sm')]: {
        padding: '1rem 3rem 4rem'
      },
      [theme.breakpoints.up('lg')]: {
        padding: '4rem 5rem 8rem'
      }
    },
    o_terms_inner_container: {
      padding: '0.8rem',
      [theme.breakpoints.up('sm')]: {
        padding: '2rem'
      }
    },
    a_title: {
      padding: '1rem 0.5rem',
      [theme.breakpoints.up('sm')]: {
        padding: '0.5rem 0.5rem 1rem'
      }
    },
    link: {
      color: (theme.palette.type === 'dark' ? '#C89DFF' : '#8833F5'),
      '&:hover': {
        color: (theme.palette.type === 'dark' ? '#E0C8FF' : '#C89DFF')
      }
    }
  })
  const classes = useStyles()
  return (
    <Fragment>
      <CssBaseline />
      <Container className={classes.o_terms_container}>
        <div>
          <Typography variant="h1" style={{ padding: '16px 4px' }}>
            Privacy Policy.
          </Typography>
          <Paper className={classes.o_terms_inner_container}>
            <Typography variant="body1" >
              Last Updated: September 25, 2018
            </Typography>
            <Typography variant="body1">
              <strong>Leia Privacy Policy - YOUR PRIVACY RIGHTS</strong>
            </Typography>
            <p>
              This Privacy Policy discloses the privacy practices for <a className={classes.link} href="www.leiainc.com">www.leiainc.com</a> and our mobile applications where this policy is posted (collectively referred to as our “site”).
            </p>
            <p>
              You agree to the terms of the privacy policy when you use this site.
            </p>
            <p>
              <strong>Information Collection, Use, and Sharing</strong>
            </p>
            <p>
              <strong>Information We Collect</strong>
            </p>
            <p>
              We have access to and collect personal information from you, including personally identifiable information that you voluntarily give to us via email, when you post or upload on our websites and applications, submit a resume and apply for jobs, email us, sign up for an account, posting a comment or text on any forum or feedback field, or through other direct contact from you.
            </p>
            <p>
              We collect the following types of information from and about you:
            </p>
            <ul>
              <li>
                <strong>Contact information.</strong>  For example, we may collect your name, user name and email address. We may also collect other contact information, such as your street address, phone number and company name. We may also collect photographs that you upload to our site.
              </li>
              <li>
                <strong>Financial information.</strong> For paid Services, we may also collect financial information regarding payment, including account numbers, and other contact information, such as your street address, phone number and company name.
              </li>
              <li>
                <strong>Information you submit or post.</strong>  We collect information or photographs you post in a public space on our site.  We also collect information when you contact us.
              </li>
              <li>
                <strong>Usage information.</strong> We collect data regarding when you visit our websites and applications, including when you view or click on content.
              </li>
              <li>
                <strong>Device and location information.</strong> We collect information about your IP address, proxy server, operating system, web browser and add-ons, device features and your mobile carrier.
              </li>
              <li>
                <strong>Other information.</strong>  If you use our websites and applications, we may collect information about the browser you’re using.  We might look at what site you came from, or what site you visit when you leave us.
              </li>
            </ul>
            <p>
              <strong>
                How We Collect Information
              </strong>
            </p>
            <p>
              We collect information in the following ways:
            </p>
            <ul>
              <li>
                <strong>We collect information directly from you.</strong>  We collect information when you submit information or content to us to publish on the website or contact us to ask questions or leave comments.  We also collect information if you contact us, e-mail us, register to use our services, or respond to a job posting
              </li>
              <li>
                <strong>We collect information from you passively.</strong>  We use tracking tools like “cookies” on this site. A cookie is a piece of data stored on a site visitor’s hard drive to help us improve your access to our site, provide you the best user experience and also to tell us which pages you find most interesting.  For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site.  Cookies can also enable us to track and target the interests of our users to enhance the experience on our site.  Usage of a cookie is in no way linked to any personally identifiable information on our site.  To learn more about these tools and show you can control them, please read the “Your Choices” section.
              </li>
              <li>
                <strong>We collect information about your devices, networks, and location.</strong> We collect information about your IP address, proxy server, and device features and location.
              </li>
              <li>
                <strong>We also collect information that we obtain from third parties.</strong> We receive the URL of the site you came from and the one you go to next.
              </li>
            </ul>
            <p><strong>How We Use Your Information</strong></p>
            <p>We use your information in the following ways:</p>
            <ul>
              <li>
                <strong>To manage your account.</strong>  We use your information to be able to associate your purchases or downloads with your account.

              </li>
              <li>
                <strong>We use information to communicate with you.  </strong>We may contact you about your account or feedback you leave for us.  We use your information to respond to your feedback or inquiries.  We may also contact you about this Privacy Policy or our Terms.
              </li>
              <li>
                <strong>We use information to improve our website and applications.  </strong>We may use your information to provide more relevant and useful content, or to make our website and applications we provide through the website better.  We might use your information to customize your experience with us.  We may combine information we get from you with information about you we get from third parties.
              </li>
              <li>
                <strong>We use information for marketing purposes.</strong>  For example, we may send you information about new website, software or service features or updates and special offers from Leia.  These may be third party products, services or offers we think you might find interesting.
              </li>
              <li>
                <strong>We use information for security purposes.</strong>  We may use your information to protect our company, our users and our website.
              </li>
              <li>
                <strong>We use information to talk to parents or guardians to get permission.</strong>  We may use contact information to get permission from parents for children who want to use our website or services.
              </li>
              <li>
                <strong>We use information as otherwise permitted by law or as we may notify you.</strong>
              </li>
            </ul>
            <p>
              <strong>
                When We Share Information With Third Parties
              </strong>
            </p>
            <p>We may share your information for our business purposes and as legally permitted, including:
            </p>
            <ul>
              <li>
                <strong>On our site.</strong> For example, we may display your photograph on our site. We may also display information that you submit to a public space on our site.
              </li>
              <li>
                <strong>If we think we have to in order to comply with the law.</strong> We will share information to respond to a court order or subpoena.  We may also share if there is a request from a government agency or investigatory body.
              </li>
              <li>
                <strong>With third parties who perform services on our behalf.  </strong>We share information with vendors who operate our website or send emails for us.
              </li>
              <li>
                <strong>With our affiliates and business partners.  </strong>We will share anonymized information with our affiliated companies and our business partners.
              </li>
              <li>
                <strong>With a successor to all or part of our business. </strong>For example, if part of our business was sold we may give our customer list as part of that transaction.
              </li>
              <li>
                <strong>With payment processors. </strong>We share information for order processing and credit card processing when you purchase content from our websites and applications.
              </li>
              <li>
                <strong>
                  For other reasons we may describe to you.
                </strong>
              </li>
            </ul>
            <p><strong>How We Secure Your Personal Information</strong></p>
            <p>The Internet is not 100% secure. We cannot promise that your use of our websites, apps and Services will be completely safe. We encourage you to use caution when using the Internet. We use reasonable means to safeguard personal information under our control.  A username and a password are needed to access certain areas of our websites, apps and Services. It is your responsibility to protect your username and password.</p>
            <p><strong>Third Parties</strong></p>
            <p>We may allow third parties to post advertisements, applications or links on our website so you can access, interact with and use their advertisements, websites and applications throughout our site.  These third parties may place cookies on certain pages of our website.  This Privacy Policy does not cover those third parties or their use of cookies or information you provide to them through interaction with their advertisements, websites or applications.</p>
            <p><strong>Where We Store Information</strong></p>
            <p>Our sites are generally operated in the United States.  If you are located outside of the United States, please be aware that any information you provide to us may be transferred to the United States. Please also be aware that by using our sites and giving us your information, you consent to such transfer.  All information will be stored within the United States, except where we are required by local law to store such information elsewhere.  You also understand that the United States may not provide the same level of protections as the laws of your country.</p>
            <p><strong>Children</strong></p>
            <p>We do not knowingly collect personal information from children under 13 without verifiable parental consent.  If we learn that we have collected personal information from a child under 13 without such consent, we will delete that information as quickly as possible.  If you believe that we might have information from a child under 13, please contact us at <a className={classes.link} href="mailto:feedback@leialoft.com">feedback@leialoft.com</a>. </p>
            <p><strong></strong></p>
            <p>
              The Leia website and applications may contain links to third party sites.  If you click on one of those links, you will be taken to websites we do not control.  This Privacy Policy does not apply to the information collection practices of that website.  You should read the privacy policies of other websites carefully.  We are not responsible for these third party sites.
            </p>
            <p><strong>Your Choices</strong></p>
            <p>
              You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via email at <a className={classes.link} href="mailto:feedback@leialoft.com">feedback@leialoft.com</a>.
            </p>
            <ul>
              <li>See what data we have about you, if any.</li>
              <li>Change/correct any data we have about you.</li>
              <li>Have us delete any data we have about you.</li>
              <li>Express any concern you have about our use of your data.</li>
            </ul>
            <p>You can choose not to provide personal information.  However, if you don’t provide us with information, some website features may not work.  You can also choose to configure your browser not to accept cookies. If you do so, some parts of our website may not work.</p>
            <p><strong>California Privacy Rights</strong></p>
            <p>
              If you reside in California, you have the right to ask us one time each year if we have shared personal information with third parties for their direct marketing purposes.  To make a request, please send us an email at the address listed below, or write to us at the address listed below.  Indicate in your letter that you are a California resident making a “Shine the Light” inquiry.
            </p>
            <p><strong>EU Privacy Rights</strong></p>
            <p>
              We want to draw your attention on your rights regarding your personal information you provided us with:
            </p>
            <ul>
              <li>
                You can request Leia to access to and rectify or erase your personal information, or to restrict or object to their processing;
              </li>
              <li>
                You can request Leia to exercise your right to data portability;
              </li>
              <li>
                You can withdraw at any time your consent to the processing of your personal information of other purposes than (i) the execution of an agreement between yourself and Leia or (ii) the compliance of Leia with legal obligation to which it is subject, it being specified that such withdrawal shall not affect the lawfulness of processing based on such consent before such withdrawal;
              </li>
              <li>
                You can lodge a complaint with the relevant European Union or local Member State authorities.
              </li>
            </ul>
            <p><strong>Updates</strong></p>
            <p>
              Our Privacy Policy may change from time to time and the latest version of this Privacy Policy will be posted on this page.
            </p>
            <p>
              If you have questions or feel that we are not abiding by this Privacy Policy, please call us at or contact us by email at <a className={classes.link} href="mailto:feedback@leialoft.com">feedback@leialoft.com</a> or by mail at the following address.
            </p>
            <p>
              <strong>
              Leia, Inc.<br/>
              2440 Sand Hill Road Suite 100<br/>
              Menlo Park, CA 94025<br/>
              USA<br/>
              </strong>
            </p>
            <p>
              Please include your email address, name, address and telephone number to help us handle your request.
            </p>
          </Paper>
        </div>
      </Container>
    </Fragment>
  )
}

export default PrivacyPolicy
