import React from 'react'
import SharedCardHalfLg from '../sharedComponents/SharedCardHalfLg'
import SharedCardHalfXs from '../sharedComponents/SharedCardHalfXs'
import {
  CssBaseline,
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import CertificationStyles from './CertificationStyles'

export default function OverviewView () {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...CertificationStyles(theme)
  })
  const classes = useStyles()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <>
      <CssBaseline/>
      <main className={classes.o_layout_Home}>
        <div className={classes.o_section_container}>
          <Grid container>
            <SharedCardHalfLg
              label="leialoft® certification"
              title="Overview."
              fontVariant="h4"
              padding={isMediumScreen ? '0 16px 0 0' : '0 0 8px'}
              paddingTop={isMediumScreen && '35px'}
              summary="The LeiaLoft® Certification Review Board will grant certification to creators that demonstrate an understanding of the modifications required to adapt and create stunning Lightfield content. Creators will be asked to submit two pieces of content for review. This content will need to leverage the LitByLeia display’s unique properties, such as depth. We welcome your ideas and creativity!"
              summary2="A submission that fails the approval stage is eligible for resubmission. To certify, 2 pieces of content must pass sequentially."
              listItemText="Complete a review of the LeiaLoft® Certification program tutorials, example applications and documentation."
              listItemText1="Submit two examples for approval by LeiaLoft® Certification Review Board. The Board will review based on Visual standards."
            />
            <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
              <SharedCardHalfXs
                padding={isMediumScreen ? '0 0 16px 16px' : '8px 0 8px'}
                title="Computer graphics standards."
                image={(theme.palette.type === 'dark' ? require('../assets/icons/photography_white.svg') : require('../assets/icons/photography_black.svg'))}
                summary="Content will be reviewed for a number of visual standards including: comfort, design, and experience."
              />
              <SharedCardHalfXs
                padding={isMediumScreen ? '16px 0 0 16px' : '8px 0 8px'}
                title="Photography standards."
                image={(theme.palette.type === 'dark' ? require('../assets/icons/cg_white.svg') : require('../assets/icons/cg_black.svg'))}
                // image={(theme.palette.type === 'dark' ? require('../assets/icons/photography_black.svg') : require('../assets/icons/photography_white.svg'))}
                summary="Content will be reviewed for a number of visual standards including: comfort, lighting, and  composition."
              />
            </Grid>
          </Grid>
        </div>
      </main>
    </ >
  )
}
