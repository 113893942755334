import React, { Fragment } from 'react'
import { makeStyles, Typography, CssBaseline, Container, Paper, useTheme } from '@material-ui/core'

const TermsOfService = () => {
  const theme = useTheme()
  const useStyles = makeStyles({
    o_terms_container: {
      [theme.breakpoints.up('xs')]: {
        paddingBottom: 16
      },
      [theme.breakpoints.up('sm')]: {
        padding: '1rem 3rem 4rem'
      },
      [theme.breakpoints.up('lg')]: {
        padding: '4rem 5rem 8rem'
      }
    },
    o_terms_inner_container: {
      padding: '0.8rem',
      [theme.breakpoints.up('sm')]: {
        padding: '2rem'
      }
    },
    a_title: {
      padding: '1rem 0.5rem',
      [theme.breakpoints.up('sm')]: {
        padding: '0.5rem 0.5rem 1rem'
      }
    },
    link: {
      color: (theme.palette.type === 'dark' ? '#C89DFF' : '#8833F5'),
      '&:hover': {
        color: (theme.palette.type === 'dark' ? '#E0C8FF' : '#C89DFF')
      }
    }
  })
  const classes = useStyles()
  return (
    <Fragment>
      <CssBaseline />
      <Container className={classes.o_terms_container}>
        <div>
          <Typography variant="h1" style={{ padding: '16px 4px' }}>
            Terms of Service.
          </Typography>
          <Paper className={classes.o_terms_inner_container}>
            <p><strong>Leia Terms of Service</strong></p>
            <p>Last updated July 23, 2018.</p>
            <p><strong>Welcome to Leia!</strong></p>
            <p>
              These terms of service sets forth the agreement (this “Agreement”) between you and Leia, Inc. (herein referred to as “Leia” “we” or “us”) governing your use of the products and services we offer through our websites and applications (the “Services;” the Services include the Leia website, <a className={classes.link} href="www.leiainc.com">www.leiainc.com</a> and LeiaLoft, <a className={classes.link} href="www.leialoft.com">www.leialoft.com</a> and Content (“Content” includes media, apps, software, information, text, graphics and other digital content or services offered through LeiaLoft).
            </p>
            <p>
              By using our Services you agree to the following terms.  Please read them carefully and know that you may be subject to additional terms for specific Services offered by Leia.  If you do not understand the Terms, or do not accept any part of them, then you should not use the Services.
            </p>
            <p>
              <strong>Using Leia </strong>
            </p>
            <p>
              By using our Services you agree to the following: (a) you will not use any of the Services for any purpose that is unlawful, tortious, abusive, intrusive on another's privacy, harassing, libelous, defamatory, embarrassing, obscene, threatening, or hateful; (b) you will not upload, post, reproduce, or distribute any information, content, software, or other material protected by copyright or any other intellectual property right (as well as rights of publicity and privacy) without first obtaining the permission of the owner of such rights; (c) you will not collect or store personal data about other users; (d) you will not use the Services or Content for any commercial purpose not expressly approved by Leia in writing; (e) you will not upload, post, email, or otherwise transmit to or through the Services any advertising or promotional materials or any other form of solicitation or unauthorized communication; (f) you will not upload, post, email, or otherwise transmit to or through the Services any material that contains viruses or any other computer code, files, or programs which might interrupt, limit, or interfere with the functionality of any computer software or hardware or telecommunications equipment.
            </p>
            <p><strong>License to Use</strong></p>
            <p>
              Leia offers you a limited, non-exclusive, non-transferable, non-sub licensable license to access the Services and to access, download, and make personal and non-commercial use of Content available on LeiaLoft and through the Services.  You may not reproduce, duplicate, copy, sell, resell, visit, or otherwise exploit for any reason any part of the Leia or LeiaLoft websites, the Content, or the Services for any commercial purpose without the express written consent of Leia.  You may only use the Services and Content as permitted by law.
            </p>
            <p>
              By submitting, displaying, posting, publishing or uploading any content, including information, text, graphics or other materials, on or to the Services, you grant Leia a non-exclusive, fully-paid, royalty-free, and perpetual license to use, modify, publicly perform, publicly display, reproduce, create derivative works from and distribute your content. You also grant to other users of the Services a non-exclusive, fully-paid, royalty-free, and perpetual license to access your Content during the course of their use of the Services as authorized herein.  You retain ownership of all content you submit, post, display, or otherwise make available on LeiaLoft and on the Services.
            </p>
            <p>
              All rights not expressly granted to you in these Terms are expressly reserved and retained by Leia and its affiliates, parents, and subsidiaries.
            </p>
            <p><strong>No Interferences</strong></p>
            <p>
             You will not interfere with, interrupt, destroy or limit the functionality of the Services or any of Leia’s computer software or hardware or telecommunications equipment. You may not use the Services in any manner that could damage, disable, overburden, or impair any Leia server, or interfere with any other party’s use of the Services. You may not attempt to gain unauthorized access to Leia or the Services, third party accounts, computer systems or networks connected to Leia or the Services through hacking, cracking, counterfeit software, password mining or any other means. You may not obtain or attempt to obtain any content or information through any means not intentionally made available through the Services. You may not reverse engineer, decompile or disassemble any software accessed through the Services.
            </p>
            <p><strong>Amendments</strong></p>
            <p>
              Leia may make changes to the terms of this Agreement and to any Services it offers and to the availability of the Services, at any time, without notice to you. If you do not agree with the changes, you should discontinue using the Services. If you continue using the Services after such changes, you are deemed to have accepted such changes to the terms of this Agreement.
            </p>
            <p><strong>Intellectual Property</strong></p>
            <p>
              You agree that the Services, including but not limited to Content, graphics, user interface, audio clips, video clips, editorial content, and the scripts and software used to implement the Services, contain proprietary information and material that is owned by Leia and/or its licensors, and is protected by applicable intellectual property and other laws, including but not limited to copyright.  You agree that you will not use such proprietary information or materials in any way whatsoever except for use of the Services for personal, noncommercial uses in compliance with this Agreement.  No portion of the Services may be reproduced in any form or by any means, except as expressly permitted by this Agreement.  You agree not to modify, rent, loan, sell, or distribute the Services and Content in any manner, and you shall not exploit the Services in any manner not expressly authorized.
            </p>
            <p><strong>Copyright Notice</strong></p>
            <p>
              Leia respects the intellectual property rights of others. If you think somebody is violating your copyright, you can submit a notice of copyright infringement to us. We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the U.S. Digital Millennium Copyright Act, 17 U.S.C. § 512.
            </p>
            <p>In order to process a copyright infringement notice we require the following: </p>
            <ol>
              <li>
                Your name, mailing address, telephone number and email address.
              </li>
              <li>
                Sufficient detail about the copyrighted work;
              </li>
              <li>
                The URL or other specific location on our websites that contains the material that you claim infringes your copyright;
              </li>
              <li>
                A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law (i.e. “I have a good faith belief that use of the copyrighted materials described above as allegedly infringing is not authorized by the copyright owner, its agent, or the law.”);
              </li>
              <li>
                A statement by you that the information contained in your notice is accurate and that you attest under the penalty of perjury that you are the copyright owner or that you are authorized to act on the copyright owner's behalf (i.e. “I swear, under penalty of perjury, that the information in the notification is accurate and that I am the copyright owner or am authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.”).
              </li>
              <li>
                An electronic or physical signature of the owner of the copyright or a person authorized to act on the owner's behalf.
              </li>
            </ol>
            <p>Your notice can be sent to our copyright agent at:</p>
            <p>
              Leia, Inc.<br />
              2440 Sand Hill Rd. #100<br />
              Menlo Park, CA 94025<br />
              USA
            </p>
            <p>
              After we receive a proper written notice, we will expeditiously remove or disable the allegedly infringing content. We will also notify the user and, if requested, provide the report to the user. Please note that your notice will be forwarded to the user who provided the allegedly infringing content (with your personal information removed). We may exclude repeat infringers from our website and Services. 
            </p>
            <p>
              If a user of the Services believes that their content was removed or disabled by mistake or misidentification, the user can send us a written counter-notification which includes the following:
            </p>
            <ol>
              <li>
                The user’s name, mailing address, telephone number and email address.
              </li>
              <li>
                Identification of the material that has been removed or disabled and the URL or other specific location on our websites at which the material appeared before it was removed or disabled.
              </li>
              <li>
                A statement that the user consents to the jurisdiction of the Federal District Court in which the user’s address is located, or [San Jose], CA if the user’s address is outside the United States.
              </li>
              <li>
                A statement under penalty of perjury that the user has a good faith belief that the material in question was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled (i.e. “I swear, under penalty of perjury, that I have a good faith belief that each search result, message, or other item of content identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled, or that the material identified by the complainant has been removed or disabled at the URL identified and will no longer be shown.”)
              </li>
              <li>
                The user’s physical or electronic signature.
              </li>
            </ol>
            <p>
             Leia will restore the removed or disabled content after 10 business days from receipt of a proper written counter notification, unless our copyright agent first receives notice that a court action has been filed to restrain the user from engaging in infringing activity related to the removed or disabled content.
            </p>
            <p>
              
            </p>
            <p><strong>Account</strong></p>
            <p>
              Certain Services offered on Leia may require you to create an account with us.  You are solely responsible for maintaining the confidentiality of your account.  You agree to notify Leia in the event of any unauthorized activity on your account.
            </p>
            <p><strong>Transactions</strong></p>
            <p>
              Through our website and applications, you may be able to purchase certain Content and pay for Services.  Additional terms and conditions may apply to such purchases.  To be able to pay for such Services, you must supply certain information relevant to your transaction, including, without limitation, your credit or debit card number, the expiration date of your credit or debit card, the name on your credit or debit card, your billing address, and your shipping address.  YOU REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE ANY CREDIT OR DEBIT CARD OR OTHER PAYMENT METHOD UTILIZED IN CONNECTION WITH ANY TRANSACTION.  By submitting such information, you grant to Leia and/or any required third parties the right to provide and transmit such information for purposes of facilitating the completion of transactions initiated by you or on your behalf.  Verification of information may be required prior to the acknowledgment or completion of any transaction.
            </p>
            <p><strong>Third Party Platforms</strong></p>
            <p>
              Leia may hyperlink to platforms not maintained by Leia or related to Leia or the Services.  These hyperlinks are provided as a service to users and are not sponsored by or affiliated with Leia, and Leia makes no representations or warranties about the content, completeness, accuracy or fitness for use of those third party platforms.  Information you submit at a third party platform is subject to the terms of that platform's privacy policy, and Leia has no control over how your information is collected, used, or otherwise handled.
            </p>
            <p><strong>Privacy</strong></p>
            <p>
              Leia’s Privacy Policy applies to your use of this site and your use of Leia and the Services.  You can go to Leia’s Privacy Policy by clicking here <a className={classes.link} href="http://www.leialoft.com/us/privacypolicy">http://www.leialoft.com/us/privacypolicy</a>. 
            </p>
            <p><strong>Limitation of Liability</strong></p>
            <p>
              WHERE ALLOWED BY LAW, LEIA WILL NOT BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE SERVICES, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, OR OTHERWISE) AND WHERE ALLOWED BY LAW, THE TOTAL LIABILITY OF LEIA AND LEIA’S SUPPLIERS AND DISTRIBUTORS FOR ANY CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES, EVEN IF LEIA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p><strong>Warranty</strong></p>
            <p>
              YOU UNDERSTAND AND AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK.  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SERVICES PERFORMED OR PROVIDED BY LEIA ARE PROVIDED "AS IS" AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LEIA HEREBY DISCLAIMS ALL WARRANTIES OR OTHER PROMISES OF ANY KIND AS TO THE SERVICES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, QUIET ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD-PARTY RIGHTS.  LEIA DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND YOU AGREE THAT FROM TIME TO TIME LEIA MAY REMOVE THE SERVICES FOR INDEFINITE PERIODS OF TIME, CANCEL THE SERVICES AT ANY TIME, OR OTHERWISE LIMIT OR DISABLE YOUR ACCESS TO THE SERVICES WITHOUT NOTICE TO YOU.
            </p>
            <p><strong>Indemnification</strong></p>
            <p>
              You agree and hereby release, indemnify and defend Leia from all claims, demands, losses, expenses and costs (including reasonable attorneys’ fees) arising out of or related to:  (i) your use of the Services or the Content, (ii) the content you provide to Leia through the Services; (iii) your conduct or interactions with other users of the Services; or (iv) or your breach of any part of this Agreement.  We will promptly notify you of any such claim, and will provide you (at your expense) with reasonable assistance in defending the claim.  You will allow us to participate in the defense and will not settle any such claim without our prior written consent.  We reserve the right, at our own expense, to assume the exclusive defense of any matter otherwise subject to indemnification by you.  In that event, you will have no further obligation to defend us in that matter.
            </p>
            <p><strong>Governing Law</strong></p>
            <p>
              You agree that all matters relating to your use of the Services, including all disputes shall be governed by laws of the state of California and federal law, without regard to any conflict of law provisions.
            </p>
            <p><strong>Entire Agreement/ Severability</strong></p>
            <p>
              This Agreement supersedes all prior terms, agreements, discussions and writings and constitutes the entire agreement between you and us regarding your use of the Services (except as to Services that require separate written agreement with us in addition to this Agreement, including but not limited to the LeiaLoft Terms and Conditions).  If any provision in this Agreement is found to be invalid or unenforceable, then that provision shall be deemed stricken from this Agreement and will not affect the validity or enforceability of the remaining provisions of the Agreement, which will remain in full force and effect.
            </p>
            <p><strong>Relationship</strong></p>
            <p>
              This Agreement does not create a joint venture, agency, partnership, or other form of joint enterprise between you and us.  Except as expressly provided herein, neither party has the right, power, or authority to create any obligation or duty, express or implied, on behalf of the other.
            </p>
            <p><strong>Waiver</strong></p>
            <p>
              No waiver of any terms will be deemed a further or continuing waiver of such term or any other term.  Our failure to assert a right or provision under this Agreement will not constitute a waiver of such right or provision.
            </p>
          </Paper>
        </div>
      </Container>
    </Fragment>
  )
}

export default TermsOfService
