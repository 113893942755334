export default theme => ({
  root: {
    flexGrow: 1
  },
  t_appBar_container: {
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
    position: 'relative'
  },
  t_nested_appBar_container: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    position: 'relative',
    zIndex: '1000',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
    display: 'grid'
  },
  o_nested_link_container: {
    justifyContent: 'center'
  },
  m_toolbar_container: {
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4px',
      gridTemplateColumns: '1fr 1fr'
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '10% auto 5% 2%'
    }
  },
  m_toolbar_container_creator: {
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4px',
      gridTemplateColumns: '1fr 1fr'
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '145px auto 185px'
    }
  },
  a_leialoft_logo: {
    padding: '8px 0 8px 4px'
  },
  a_logo_header: {
    padding: theme.spacing(1)
  }
})
