import React, { Fragment } from 'react'
import { makeStyles, Checkbox, CssBaseline } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  checkboxRoot: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
    // figure this out later - can't get the checked hover to change. .MuiCheckbox-colorSecondary-367.Mui-checked:hover
    // 'checked &:hover': {
    //   backgroundColor: 'transparent'
    // }
  }
  // leaving all this checkbox icon stuff here in case maddy wants a custom checkbox.
  // will delete before merging to master if approved without custom checkbox =X
  // icon: {
  //   borderRadius: 5,
  //   width: 23,
  //   height: 23,
  //   boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  //   backgroundColor: '#f5f8fa',
  //   backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  //   '$root.Mui-focusVisible &': {
  //     outline: '2px auto rgba(19,124,189,.6)',
  //     outlineOffset: 2
  //   }
  // },
  // checkedIcon: {
  //   backgroundColor: 'red',
  //   backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  //   '&:before': {
  //     display: 'block',
  //     width: 23,
  //     height: 23,
  //     backgroundImage:
  //       "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
  //       " fillRule='evenodd' clipRule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
  //       "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
  //     content: '""'
  //   },
  //   'input:hover ~ &': {
  //     backgroundColor: 'none'
  //   }
  // }
}))

export default function LlCheckbox ({ state, handleChange }) {
  const classes = useStyles()
  return (
    <Fragment>
      <CssBaseline />
      <Checkbox
        color="secondary"
        disableRipple
        checked={state.checked}
        onChange={handleChange}
        name="checked"
        // checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        // icon={<span className={classes.icon} />}
        className={classes.checkboxRoot}
        style={{ height: '30px' }}
      />
    </Fragment>
  )
}
