import React from 'react'
import {
  Grid,
  Typography,
  useTheme,
  makeStyles,
  useMediaQuery
} from '@material-ui/core'
import SharedCardHalf from '../sharedComponents/SharedCardHalf'
import clsx from 'clsx'
import toolsStyles from './ToolsStyles'

const userOS = navigator.appVersion.indexOf('Mac') !== -1 ? 'mac' : 'win'

const GetStartedTools = [
  {
    title: 'Lightfield Studio',
    subtitle: 'APP',
    summary:
      'The powerful application for mastering Lightfield video. Convert your ' +
      '2D, 3D SBS, and Orbital content into stunning Lightfield video ready to ' +
      'playback on all LitByLeia devices.',
    gitbookLink: 'https://docs.leialoft.com/developer/lightfield-studio/' +
      'getting-started',
    OSDownloadUrl: {
      win: 'https://leia-services-e5a1.s3.amazonaws.com/wp-content/uploads/20201223195000/LightfieldStudio.zip ',
      mac: 'https://leia-services-e5a1.s3.amazonaws.com/wp-content/' +
        'uploads/20201103114400/LightfieldStudio_v1.2.dmg'
    },
    isImageOnly: false,
    image: null,
    imageTitle: null,
    component: null
  },
  {
    isImageOnly: true,
    image: require('../assets/images/LF_Studio.png'),
    imageTitle: 'Lightfield Studio screenshot',
    component: 'img',
    title: null,
    subtitle: null,
    summary: null,
    gitbookLink: null,
    downloadMacOsUrl: null
  },
  {
    title: 'Unity',
    subtitle: 'SDK',
    summary:
      'Enhance your projects with the multiview Leia Camera. Tools like Depth of Field and Auto Focus make it easy to design and adapt your scenes, while visualizing in real time with disparity maps and anaglyph mode.',
    gitbookLink: 'https://docs.leialoft.com/developer/unity-sdk',
    downloadUrl: 'https://leiainc.github.io/Unity/LeiaUnitySDK_Latest.zip',
    isDisabled: false,
    isImageOnly: false,
    image: null,
    imageTitle: null,
    component: null
  },
  {
    title: 'Unreal Engine',
    subtitle: 'SDK',
    summary:
      'Enliven your creations with a suite of tools that make it simple to design for multiview.',
    gitbookLink: 'https://docs.leialoft.com/developer/unreal-sdk/unreal-sdk-guide',
    downloadUrl: 'https://leiainc.github.io/Unreal/LeiaLoft_Unreal_SDK_Latest.zip'
  },
  {
    title: 'Blender',
    subtitle: 'PLUGIN',
    summary:
      'A free and open-source 3D computer graphics software toolset used for creating animated films, visual effects, art, 3D printed models, motion graphics, interactive 3D apps, VR and computer games. ',
    gitbookLink: 'https://docs.leialoft.com/developer/blender-sdk/using-blender-for-litbyleia-displays',
    downloadUrl: 'https://leiainc.github.io/Blender/Leia+Blender+SDK_Latest.zip',
    isDisabled: false,
    isImageOnly: false,
    image: null,
    imageTitle: null,
    component: null
  },
  // {
  //   isImageOnly: true,
  //   image: require('../assets/images/Video.png'),
  //   imageTitle: 'Video screenshot',
  //   component: 'img',
  //   title: null,
  //   subtitle: null,
  //   summary: null,
  //   gitbookLink: null,
  //   downloadMacOsUrl: null
  // },
  {
    title: 'Maya',
    subtitle: 'Plugin',
    summary:
      'Easily create beautiful multiview renders with the built in Arnold renderer. Simply replace your Maya camera with a LeiaCamera and seamlessly render to your LitByLeia enabled device.',
    gitbookLink: 'https://docs.leialoft.com/developer/maya-plugin',
    downloadUrl: 'https://leiainc.github.io/Maya/LeiaMayaPlugin_Latest.zip',
    isDisabled: false,
    isImageOnly: false,
    image: null,
    imageTitle: null,
    component: null
  },
  {
    title: 'After Effects',
    subtitle: 'Templates',
    summary:
      'Render in After Effects to produce compelling, multiview renders for Leia displays.',
    gitbookLink: 'https://docs.leialoft.com/developer/after-effects/using-after-effects-for-litbyleia-displays',
    downloadUrl: 'https://leia-services-e5a1.s3.amazonaws.com/wp-content/uploads/20201116201500/Leia+After+Effects+Template+v0.2.zip',
    isDisabled: false,
    isImageOnly: false,
    image: null,
    imageTitle: null,
    component: null
  },
  {
    title: 'Cinema 4D',
    subtitle: 'Guide',
    summary:
      'Our two step guide provides best practices for rendering Lightfield content in Cinema 4D.',
    gitbookLink: 'https://docs.leialoft.com/developer/cinema-4d/two-step-rendering-setup-part-i',
    downloadUrl: null,
    isDisabled: false,
    isImageOnly: false,
    image: null,
    imageTitle: null,
    component: null
  },
  {
    title: 'Android',
    subtitle: 'SDK',
    summary:
      'Render your content in multiview. Android applications can be adapted or created from scratch using a simple C API. A complete set of samples and documentation included.',
    gitbookLink: 'https://docs.leialoft.com/developer/android-sdk',
    downloadUrl: 'https://leiainc.github.io/Android/LeiaLoft_NativeAndroid_SDK_Latest.zip',
    isDisabled: false,
    isImageOnly: false,
    image: null,
    imageTitle: null,
    component: null
  },
  {
    title: 'Android Media',
    subtitle: 'SDK',
    summary:
      'The Android Media SDK provides reusable Android views and easy to use APIs to render different types of images and videos on a Leia 3D Lightfield device. A complete set of documentation and code-labs included.',
    gitbookLink: 'https://docs.leialoft.com/developer/android-media-sdk/overview',
    downloadUrl: null,
    isDisabled: false,
    isImageOnly: false,
    image: null,
    imageTitle: null,
    component: null
  }
]

export default function GetStartedView () {
  const isInBetween = useMediaQuery('(max-width: 1102px) and (min-width: 768px)')
  function pxToRem (value) {
    return `${value / 16}rem`
  }
  const theme = useTheme()
  const useStyles = makeStyles({
    ...toolsStyles(theme),
    o_container_GetStarted: {
      padding: theme.spacing(4, 0),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8, 0, 8)
      }
    },
    a_text_h2_GetStarted: {
      textAlign: 'center',
      padding: theme.spacing(0, 0, 4),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 0, 8)
      }
    },
    getContentCard: {
      display: 'grid',
      [theme.breakpoints.between('md', 'md')]: {
        height: '24rem'
      }
    },
    getContent: {
      display: 'grid',
      gridTemplateRows: 'rem auto',
      [theme.breakpoints.up('lg')]: {
        gridTemplateRows: '4rem auto'
      }
    },
    toolName: {
      gridColumn: '1 / span 1',
      alignSelf: 'center',
      [theme.breakpoints.between('sm', 'sm')]: {
        fontSize: pxToRem(45)
      },
      [theme.breakpoints.between('md', 'md')]: {
        fontSize: '4vw'
      }
    },
    toolType: {
      alignSelf: 'end',
      marginBottom: '1em',
      gridColumn: '2 / span 2',
      fontSize: pxToRem(13.5),
      textTransform: 'uppercase',
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.up('lg')]: {
        marginBottom: '1.4em'
      }
    },
    toolBody: {
      gridColumn: '1 / span 4',
      gridRow: '3 / span 3',
      [theme.breakpoints.up('sm')]: {
        gridColumn: '1 / span 2'
      }
    },
    downloadForMacOsButton: {
      padding: 4,
      lineHeight: '1.1',
      fontSize: '0.9rem'
    }
  })
  const classes = useStyles()
  return (
    <main className={classes.o_layout_Home}>
      <div className={classes.o_container_GetStarted}>
        <Typography
          variant="h2"
          className={clsx(
            classes.a_text_h2_GetStarted,
            classes.a_text_font_nantes)}
        >
          Get Started.
        </Typography>
        <Grid container spacing={2}>
          {GetStartedTools.map(tool =>
            <SharedCardHalf
              key={tool.title}
              display="none" // hide label row in card component
              title={tool.title}
              subtitle={tool.subtitle}
              fontVariant="h2"
              summary={tool.summary}
              download={tool.downloadUrl}
              userOS={userOS}
              OSDownload={tool.OSDownloadUrl}
              gitbookLink={tool.gitbookLink}
              isDisabled={tool.isDisabled}
              buttonClassName={isInBetween && classes.downloadForMacOsButton}
              isImageOnly={tool.isImageOnly}
              image={tool.image}
              component={tool.component}
              imageTitle={tool.imageTitle}
            />
          )}
        </Grid>
      </div>
    </main>
  )
}
