import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import {
  withStyles,
  CssBaseline,
  Typography,
} from '@material-ui/core'
import styles from './CreatorAppBarStyles'
import clsx from 'clsx'

const CreatorAppBarMenu = ({ classes }) => {
  return (
    <Fragment>
      <CssBaseline/>
      <div className={classes.t_header_container_desktop_creator}> {/** ONLY FOR CREATOR */}
        {/* <div className={classes.t_header_container_desktop}> COMMENTING OUT FOR LEIALOGIN 2.0 */}
        <Typography variant="subtitle2">
          <NavLink
            className={clsx(classes.a_NavLink_header, classes.a_NavLink_headerMenu)}
            activeClassName={classes.a_NavLink_header_active}
            to='/tools'>
            TOOLS
          </NavLink>
        </Typography>
        <Typography variant="subtitle2">
          <NavLink
            className={clsx(classes.a_NavLink_header, classes.a_NavLink_headerMenu)}
            activeClassName={classes.a_NavLink_header_active}
            to='/certification'>
            CERTIFICATION
          </NavLink>
        </Typography>
        <Typography className={classes.a_NavLink_headerMenu} variant="subtitle2">
          <a className={classes.link} href="https://forums.leialoft.com/" target="_blank" rel="noopener noreferrer">FORUM</a>
        </Typography>
        <Typography className={classes.a_NavLink_headerMenu} variant="subtitle2">
          <a className={classes.link} href="https://developer.leialoft.com/" target="_blank" rel="noopener noreferrer">DEVELOPER PORTAL</a>
        </Typography>
      </div>
    </Fragment>
  )
}

CreatorAppBarMenu.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CreatorAppBarMenu)
