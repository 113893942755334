import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import SharedCardFull from '../sharedComponents/SharedCardFull'
import toolsStyles from './ToolsStyles'
import { mainTheme } from '../assets/themes/MainTheme'

const BuildAnAppTool = [
  {
    label: 'LeiaLoft® store',
    title: 'Unleash the magic of lightfields.',
    summary: 'LeiaLoft® store is the destination for LitByLeia experiences, allowing for optimal discovery by users around the world. The LeiaLoft® Developers Portal enables you to create, share, and manage engaging Lightfield experiences, accessing our built-in analytics and developer tools. Whether your application is free to download, requires in-app purchase or premium models, you can configure your store listing before publishing. Get started today!',
    link: 'https://developer.leialoft.com/'
  }
]

export default function UnleashTheMagicView () {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...toolsStyles(theme),
    o_container_Unleash: {
      display: 'grid',
      padding: theme.spacing(3, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(6, 1)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(10.5, 1)
      }
    }
  })
  const classes = useStyles()
  return (
    <main style={{ backgroundColor: (theme.palette.type === 'dark' ? mainTheme.palette.primary.gray3 : mainTheme.palette.primary.gray1) }}>
      <div className={classes.o_layout_Home}>
        <div className={classes.o_container_Unleash}>
          {BuildAnAppTool.map(tool =>
            <SharedCardFull
              key={tool.title}
              label={tool.label}
              title={tool.title}
              summary={tool.summary}
              link={tool.link}
              theme={theme}
              buttonVariant="outlined"
              buttonLabel="Go to developer portal"
              target="_blank"
              color="inherit"
            />
          )}
        </div>
      </div>
    </main>
  )
}
