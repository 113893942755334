import React, { Fragment } from 'react'
import {
  makeStyles,
  Card,
  CardActions,
  CardMedia,
  Button,
  Grid,
  CardContent,
  Typography,
  Hidden,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp'
import SplitButton from '../sharedComponents/SplitButton'
import LicenseAgreementDialog from '../dialogs/LicenseAgreementDialog'
import clsx from 'clsx'
import sharedCardStyles from './SharedCardStyles'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'

function pxToRem (value) {
  return `${value / 16}rem`
}

export const useStyles = makeStyles((theme) => ({
  ...sharedCardStyles(theme),
  m_card_half: {
    height: 'fit-content',
    [theme.breakpoints.up('md')]: {
      height: '26rem'
    }
  },
  m_cardHalf_content_container: {
    display: 'grid',
    gridTemplateRows: 'auto'
  },
  m_cardAction_btn_container: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  a_NavLink_button: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem'
  },
  m_title_container: {
    display: 'flex',
    gridRow: '1',
    gridColumn: '1 / span 2',
    paddingBottom: '1.5rem',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '1rem'
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0.75rem'
    }
  },
  a_cardHalf_text_title: {
    alignSelf: 'flex-end',
    paddingRight: '0.75rem',
    [theme.breakpoints.up('sm')]: {
      lineHeight: pxToRem(63.7)
    },
    [theme.breakpoints.up('xs')]: {
      paddingRight: '0.5rem'
    }
  },
  a_cardHalf_text_subtitle: {
    alignSelf: 'flex-end',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '2rem'
    },
    [theme.breakpoints.down('xs')]: {
      lineHeight: '1.25rem'
    }
  },
  m_card_half_height: {
    height: 'fit-content',
    [theme.breakpoints.up('md')]: {
      height: '24rem'
    }
  },
  m_card_half_media: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  }
}))

export default function SharedCardHalf ({
  display,
  gridColumn,
  label,
  title,
  subtitle,
  summary,
  summary2,
  download,
  OSDownload,
  userOS,
  gitbookLink,
  backgroundColor,
  fontVariant,
  theme,
  toSubmitPath,
  toLearnMore,
  cardHalfStyle,
  listItemText,
  listItemText1,
  height,
  learnMore,
  isDisabled,
  buttonClassName,
  isImageOnly,
  image,
  component,
  imageTitle
}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [downloadLink, setDownloadLink] = React.useState(OSDownload ? OSDownload[userOS] : download)
  const handleOpen = (_, link) => {
    if (link) {
      setDownloadLink(link)
    }
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Fragment>
      {isImageOnly
        ? <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent
              className={clsx(
                classes.m_card_half_height,
                classes.m_card_half_media
              )}
              style={{ height: '100%' }}
            >
              <CardMedia
                className={classes.m_cardMedia}
                component={component}
                image={image}
                title={imageTitle}
                autoPlay
                loop
                controls
                muted
              />
            </CardContent>
          </Card>
        </Grid>
        : <Grid item xs={12} md={6}>
          <Card>
            <CardContent
              className={clsx(
                classes.o_card_layout,
                classes.m_card_half
              )}
            >
              {
                label &&
            <Typography
              variant="overline"
            >
              {label}
            </Typography>
              }
              <div className={classes.m_cardHalf_content_container}>
                <div className={classes.m_title_container}>
                  <Typography
                    style={{ gridColumn }}
                    className={classes.a_cardHalf_text_title}
                    variant={fontVariant}
                  >
                    {title}
                  </Typography>
                  <Typography
                    className={classes.a_cardHalf_text_subtitle}
                    variant="overline"
                  >
                    {subtitle}
                  </Typography>
                </div>
                <Typography
                  className={classes.a_text_body1}
                  variant="body1"
                  gutterBottom
                >
                  {summary}
                </Typography>
                {/* I'll make this DRY after launch */}
                {
                  listItemText &&
                  <List dense >
                    <ListItem>
                      <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }} />
                      <ListItemText primary={listItemText} />
                    </ListItem>
                    <ListItem>
                      <RemoveRoundedIcon style={{ alignSelf: 'flex-start', margin: '4px 4px 0' }}/>
                      <ListItemText primary={listItemText1} />
                    </ListItem>
                  </List>
                }
                <Typography
                  className={classes.a_text_body1}
                  variant="body1"
                  gutterBottom
                >
                  {summary2}
                </Typography>
              </div>
              <CardActions className={classes.m_cardAction_btn_container}>
                { !OSDownload && download && (
                  <Hidden smDown>
                    <Button variant="outlined" color="inherit" size="large" onClick={handleOpen} style={{ marginRight: 8 }}>
                        DOWNLOAD
                    </Button>
                  </Hidden>
                )}
                { OSDownload && (
                  <Hidden smDown>
                    <SplitButton
                      options={[
                        {
                          label: 'DOWNLOAD FOR WINDOWS',
                          value: OSDownload.win,
                          icon: <DownloadIcon />
                        },
                        {
                          label: 'DOWNLOAD FOR MAC',
                          value: OSDownload.mac,
                          icon: <DownloadIcon />
                        }
                      ]}
                      defaultSelectedIndex={userOS === 'win' ? 0 : 1}
                      onSelect={link => handleOpen(null, link)}
                      onClick={link => handleOpen(null, link)}
                    />
                  </Hidden>
                )}
                {gitbookLink && (
                  <Button variant="outlined" color="inherit" target="_blank" size="large" href={gitbookLink}>
                  DOCUMENTATION
                  </Button>
                )}

                { isDisabled && (
                  <Button variant="outlined" color="inherit" size="large" disabled="true" onClick={handleOpen} style={{ marginRight: 1 }}>
                      Coming Soon
                  </Button>
                )}
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
      }
      <LicenseAgreementDialog
        open={open}
        handleClose={handleClose}
        download={downloadLink}
        theme={theme}
      />
    </Fragment>
  )
}
