import React from 'react'
import LeiaLoftWhite from './LeiaLoftWhite'
import LeiaLoftBlack from './LeiaLoftBlack'
import LL_CREATOR_B from './LL_CREATOR_B'
import LL_CREATOR_W from './LL_CREATOR_W'
import LL_CREATOR_HEADER_B from './LL_CREATOR_HEADER_B'
import LL_CREATOR_HEADER_W from './LL_HEADER_CREATOR_W'
import LL_CREATOR_HEADER_TOOLS_W from './LL_CREATOR_HEADER_TOOLS_W'
import LL_CREATOR_HEADER_TOOLS_B from './LL_CREATOR_HEADER_TOOLS_B'
import LL_CREATOR_HEADER_CERTIFICATION_W from './LL_CREATOR_HEADER_CERTIFICATION_W'
import LL_CREATOR_HEADER_CERTIFICATION_B from './LL_CREATOR_HEADER_CERTIFICATION_B'
import LeiaLoftAdmin from './LeiaLoftAdmin'
import LeiaLoftDeveloper from './LeiaLoftDeveloper'

const Logo = props => {
  switch (props.name) {
  case 'll-white':
    return <LeiaLoftWhite {...props} />
  case 'll-black':
    return <LeiaLoftBlack {...props} />
  case 'll-creator-b':
    return <LL_CREATOR_B {...props} />
  case 'll-creator-w':
    return <LL_CREATOR_W {...props} />
  case 'll-creator-header-b':
    return <LL_CREATOR_HEADER_B {...props} />
  case 'll-creator-header-w':
    return <LL_CREATOR_HEADER_W {...props} />
  case 'll-creator-header-tools-w':
    return <LL_CREATOR_HEADER_TOOLS_W {...props} />
  case 'll-creator-header-tools-b':
    return <LL_CREATOR_HEADER_TOOLS_B {...props} />
  case 'll-creator-header-certification-w':
    return <LL_CREATOR_HEADER_CERTIFICATION_W {...props} />
  case 'll-creator-header-certification-b':
    return <LL_CREATOR_HEADER_CERTIFICATION_B {...props} />
  case 'leia-loft-admin':
    return <LeiaLoftAdmin {...props} />
  case 'leia-loft-developer':
    return <LeiaLoftDeveloper {...props} />
  default:
    return <div />
  }
}

export default Logo
