import React from 'react'
import SharedCardFull from '../sharedComponents/SharedCardFull'
import toolsStyles from '../tools/ToolsStyles'
import {
  useTheme,
  makeStyles
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'


const Tool = [
  {
    label: 'LEIALOFT® TOOLS',
    title: 'Transform your content.',
    summary: 'With our Creative Toolkit you can start creating content for LitByLeia devices, including our very own Lume Pad. Whether you are seeking to transform your photos or develop more immersive experiences in your applications, Leia has the tools to enable your creativity! Take your idea from concept to creation with controls made for editing Lightfield content.',
    // link: 'https://leia-inc.gitbook.io/leialoftdocs/'
  }
]

export default function TransformYourContentView () {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...toolsStyles(theme),
    o_container_Transform: {
      display: 'grid',
      padding: theme.spacing(3, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(6, 1)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(10.5, 1)
      }
    }
  })
  const classes = useStyles()
  return (
    <main >
      <div className={classes.o_layout_Home}>
        <div className={classes.o_container_Transform}>
          {Tool.map(tool =>
            <SharedCardFull
              key={tool.title}
              label={tool.label}
              title={tool.title}
              summary={tool.summary}
              // link={tool.link}
              theme={theme}
              buttonVariant="outlined"
              buttonLabel="Learn More"
              to="/tools"
              component={NavLink}
            />
          )}
        </div>
      </div>
    </main>
  )
}
