import React from 'react'
import clsx from 'clsx'
import {
  Card,
  Grid,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core'
import sharedCardStyles from './SharedCardStyles'

export default function SharedCardHalfXs ({
  title,
  subtitle,
  image,
  summary,
  padding
}) {
  const theme = useTheme()
  const useStyles = makeStyles({
    ...sharedCardStyles(theme),
    m_cardHalfXs_grid: {
      padding: theme.spacing(2)
    },
    m_cardHalfXs_content_container: {
      display: 'grid',
      height: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'auto 20%',
        gridTemplateRows: '20% auto 40%',
        padding: theme.spacing(5)
      }
    },
    a_text_title: {
      gridColumn: '1/span 3',
      gridRow: 1,
      [theme.breakpoints.down('lg')]: {
        alignSelf: 'center'
      }
    },
    a_text_subtitle: {
      gridColumn: 2
    },
    a_text_summary: {
      gridRow: 2,
      gridColumn: 1,
      alignSelf: 'center'
    },
    a_media_image: {
      height: 'auto',
      width: 'auto',
      gridColumn: 3,
      justifySelf: 'end',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'end',
        width: '50px',
        height: 'auto'
      },
      [theme.breakpoints.up('md')]: {
        gridRow: 3
      }
    }
  })
  const classes = useStyles()
  return (
    <Grid
      item
      xs={12}
      style={{ padding }}
    >
      <Card>
        <CardContent
          className={clsx(
            classes.m_cardHalfXs_content_container
          )}
        >
          <Typography
            className={classes.a_text_title}
            variant="h5"
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            className={classes.a_text_subtitle}
            variant="overline"
            gutterBottom
          >
            {subtitle}
          </Typography>
          <Typography
            className={classes.a_text_summary}
            variant="body1"
            gutterBottom
          >
            {summary}
          </Typography>
          <CardMedia
            className={classes.a_media_image}
            component="img"
            image={image}
            title="Image title"
          />
        </CardContent>
      </Card>
    </Grid>
  )
}
