import { createMuiTheme } from '@material-ui/core/styles'
import { theme, mainTheme } from './MainTheme'

export const darkTheme = createMuiTheme({
  ...theme,
  // add palette type dark to change main background color and button text color
  palette: {
    type: 'dark',
    // this palette also needs to be here to quickly set color with props
    secondary: {
      main: '#C89DFF',
      hover: '#e0c8ff',
      disabled: '#b098c3'

    },
    background: {
      paper: mainTheme.palette.primary.main
    },
    error: {
      main: '#FF7777'
    }
  },
  overrides: {
    ...theme.overrides,
    MuiAppBar: {
      colorDefault: {
        backgroundColor: mainTheme.palette.primary.main
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#c89dff'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: '#D0D0D0',
        '&$focused': {
          color: '#71C8FF'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#D0D0D0'

        },
        '&$focused $notchedOutline': {
          borderColor: '#71C8FF'
        }
      }
    }
  }
})
