import React, { Fragment, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import ToolsView from '../tools/ToolsView'
import DefaultAppBar from '../navigation/header/DefaultAppBar'
// import FooterView from '../navigation/footer/FooterView'
import CreatorFooterView from '../navigation/footer/CreatorFooterView'
import ServerErrorView from '../ServerErrorView'
// import { EntryRoutesList, PrivateRoutesList } from './RoutesList'
// import LogoAppBar from '../navigation/header/LogoAppBar'
import { GET_USER_PERMISSIONS } from '../cache'
import { useQuery } from '@apollo/client'
// import SecondaryAppBar from '../navigation/header/privateAppBar/SecondaryAppBar'
// import MobileDrawer from '../navigation/header/MobileDrawer'
import MobileDrawerCreator from '../navigation/header/creatorAppBar/MobileDrawerCreator'
import { topics } from '../navigation/topics'
import LicenseAgreement from '../terms/LicenseAgreement'
import PrivacyPolicy from '../terms/PrivacyPolicy'
import TermsOfService from '../terms/TermsOfService'
import { ThemeProvider, createMuiTheme } from '@material-ui/core'
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded'
import Brightness3RoundedIcon from '@material-ui/icons/Brightness3Rounded'
import { lightTheme } from '../assets/themes/LightTheme'
import { darkTheme } from '../assets/themes/DarkTheme'
import GetCertifiedView from '../getCertified/GetCertifiedView'
import SuccessView from '../getCertified/SuccessView'
import CertifcationView from '../certification/CertificationView'
import CreatorsView from '../creators/CreatorsView'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// const PrivateRoute = ({ children, ...rest }) => {
//   return (
//     <Fragment>
//       <Route
//         {...rest}
//         render={({ location }) =>
//           localStorage.getItem('token') ? (
//             children
//           ) : (
//             <Redirect
//               to={{
//                 pathname: '/login',
//                 state: { from: location }
//               }}
//             />
//           )
//         }
//       />
//     </Fragment>
//   )
// }

// a wrapper for <Route> to map through entry routes only as they have their own logo for the header
// entry routes are login and user registration routes
// the entry routes do not have a footer
// const EntryRoute = (route) => {
//   return (
//     <Fragment>
//       <LogoAppBar />
//       <Route
//         path={route.path}
//         render={props => (
//           <route.component {...props} />
//         )}
//       />
//     </Fragment>
//   )
// }

// the default layout to render authenticated and unauthenticated views of headers and footer
// when in the home page and all private routes
const DefaultLayout = () => {
  const [theme, setTheme] = React.useState(true)
  const handleThemeClick = () => {
    setTheme(!theme)
  }
  const icon = !theme ? <Brightness3RoundedIcon /> : <WbSunnyRoundedIcon />
  const appliedTheme = createMuiTheme(theme ? darkTheme : lightTheme)
  const [isMobileMenuShowing, setIsMobileMenuShowing] = useState(false)
  const history = useHistory()
  const handleLogOut = async () => {
    localStorage.clear()
    await undefined // to go back to home page when user clicks "log out" from a protected route, otherwise private routing will redirect to login page
    history.go('/')
  }
  const { loading, data, error } = useQuery(GET_USER_PERMISSIONS)
  if (loading) return 'Loading...'
  if (error) return `Error: ${error.message}`
  const user = Object.values(data).toString()

  function toggleMobileMenu () {
    setIsMobileMenuShowing(!isMobileMenuShowing)
  }

  return (
    <Fragment>
      <ThemeProvider theme={appliedTheme}>
        <DefaultAppBar
          user={user}
          topics={topics}
          handleLogOut={handleLogOut}
          handleThemeClick={handleThemeClick}
          icon={icon}
          theme={theme}
          handleMobileMenuClick={toggleMobileMenu} />
        <MobileDrawerCreator
          isShowing={isMobileMenuShowing}
          user={user}
          handleLogOut={handleLogOut}
          handleThemeClick={handleThemeClick}
          icon={icon}
          // topics={ topics.filter(topic => topic.show === 'header') }
          setIsShowing={setIsMobileMenuShowing} />
        {/* COMMENTING OUT FOR LEIALOGIN 2.0 */}
        {/* <MobileDrawer
          isShowing={isMobileMenuShowing}
          user={user}
          handleLogOut={handleLogOut}
          topics={ topics.filter(topic => topic.show === 'header') }
          setIsShowing={setIsMobileMenuShowing} /> */}
        <Switch>
          <Route exact path="/"><CreatorsView/></Route>
          <Route path="/tools"><ToolsView/></Route>
          <Route path="/certification"><CertifcationView/></Route>
          <Route path="/get-certified"><GetCertifiedView/></Route>
          <Route path="/get-certified-success"><SuccessView/></Route>
          {/* DO NOT EDIT THE TERMS PATHS - BEING USED IN MANY OTHER PROJECTS */}
          <Route path="/license-agreement"><LicenseAgreement/></Route>
          <Route path="/terms-of-service"><TermsOfService/></Route>
          <Route path="/privacy-policy"><PrivacyPolicy/></Route>
          {/* COMMENTING OUT ALL AUTH ROUTES UNTIL WE IMPLEMENT LEIALOGIN 2.0 */}
          {/* <PrivateRoute >
            {PrivateRoutesList.map((route, id) =>
              <Route
                key={id}
                path={route.path}
                render={() =>
                  <SecondaryAppBar user={user} topics={topics}/>
                }
              />
            )}
          </PrivateRoute> */}
          <Route path="/servererror"><ServerErrorView /></Route>
        </Switch>
        <CreatorFooterView />
        {/* FOOTER FOR LEIALOGIN 2.0 */}
        {/* <FooterView user={user} handleLogOut={handleLogOut} theme={theme} topics={topics} /> */}
      </ThemeProvider>
    </Fragment>
  )
}

const Routes = () => {
  return (
    <Fragment>
      <Switch>
        {/* {EntryRoutesList.map((route, i) => (
          <EntryRoute key={i} {...route} />
        ))} */}
        <Route>
          <DefaultLayout />
        </Route>
      </Switch>
    </Fragment>
  )
}

export default Routes
