import React, { Fragment } from 'react'
import {
  makeStyles,
  Typography,
  Grid,
  CssBaseline,
  useTheme
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import LlNavLink from '../sharedComponents/LlNavLink'
import Image from '../assets/images/Leia-platform-narrow-waves.png'
import { mainTheme } from '../assets/themes/MainTheme'
import GetCertifiedForm from './GetCertifiedForm'
import { useMutation, gql } from '@apollo/client'

const SEND_CREATOR_SUBMISSION_EMAIL = gql`
  mutation sendCreatorSubmissionEmail($templateData: CreatorSubmissionEmailTemplateData!) {
    Notify {
      sendCreatorSubmissionEmail(templateData: $templateData) 
    }
  }
`

export default function GetCertifiedView () {
  const theme = useTheme()
  const useStyles = makeStyles({
    t_layout_centered: {
      flex: '1 0 auto',
      backgroundImage: theme.palette.type === 'dark'
        ? 'url(' + Image + ')'
        : null,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '160%',
      backgroundPosition: 'bottom left',
      backgroundColor: theme.palette.type === 'dark'
        ? mainTheme.palette.primary.main
        : mainTheme.palette.primary.leiaLight
    },
    o_grid_container: {
      [theme.breakpoints.down('xs')]: {
        padding: '1rem',
        margin: '0rem auto 6rem'
      },
      [theme.breakpoints.up('sm')]: {
        margin: '1rem auto 10rem'
      },
      [theme.breakpoints.up('md')]: {
        margin: '3rem auto 14rem'
      },
      [theme.breakpoints.up('lg')]: {
        margin: '3rem auto 20rem'
      },
      [theme.breakpoints.up('xl')]: {
        margin: '3rem auto 32rem'
      },
      maxWidth: theme.spacing(43)
    }
  })
  const classes = useStyles()
  const [state, setState] = React.useState({
    checked: false
  })
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }
  const history = useHistory()

  const [Notify, { loading }] = useMutation(
    SEND_CREATOR_SUBMISSION_EMAIL,
    {
      onCompleted ({ Notify }) {
        console.log(Notify)
      },
      onError ({ graphQLErrors }) {
        if (graphQLErrors) {
          graphQLErrors.forEach((error) => {
            console.log('full error:', error, 'error.message:', error.message)
            history.push({ pathname: './servererror', state: { detail: 'Username is not available.' } })
          })
        }
      }
    }
  )
  if (loading) return <p>Loading...</p>
  const onSubmit = (data, e) => {
    e.preventDefault()
    Notify({
      variables: {
        templateData: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          submissionLink1: data.submissionLink1,
          submissionLink2: data.submissionLink2
        }
      }
    })
    history.push('/get-certified-success')
  }
  return (
    <Fragment>
      <CssBaseline />
      <main className={classes.t_layout_centered}>
        <Grid container className={classes.o_grid_container}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              gutterBottom
            >
              Get certified.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              gutterBottom
            >
              Become a certified creator by submitting your Lightfield content for approval. Refer to our <LlNavLink to="/certification" target="_blank" name="Submission Requirements"/> for details. Two examples are required. Enter your name, the cloud drive link (Box, Dropbox, Google Drive, One Drive, iCloud, etc.) to your content and your email address.
            </Typography>
          </Grid>
          <GetCertifiedForm
            onSubmit={onSubmit}
            handleChange={handleChange}
            state={state}
          />
        </Grid>
      </main>
    </Fragment>
  )
}
