import React, { Fragment } from 'react'
import {
  Dialog,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core'
import LlCheckbox from '../sharedComponents/LlCheckbox'
import LlNavLink from '../sharedComponents/LlNavLink'

const useStyles = makeStyles((theme) => ({
  m_Dialog_container: {
    display: 'grid',
    gridGap: '1rem',
    padding: '1.5rem'
  },
  a_Dialog_title: {
    gridRow: '1/1'
  },
  m_Dialog_content: {
    gridRow: '2/2',
    display: 'flex'
  },
  a_Dialog_text: {
    padding: '0 9px 9px 2px'
  },
  m_Dialog_actions: {
    display: 'flex',
    gridRow: '3/3',
    justifyContent: 'space-between'
  },
  backdrop: {
    opacity: '0.7 !important',
    backgroundColor: '#434343'
  },
  a_button: {
    '&.Mui-disabled': {
      color: '#fff',
      background: 'linear-gradient(115.84deg, #6B2ACC 24.21%, #EA40E8 96.52%, #CB3CC9 96.52%)',
      opacity: '0.5'

    }
  }
}))

export default function LicenseAgreementDialog ({ download, open, handleClose }) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    checked: false
  })
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        BackdropProps= {{ classes: { root: classes.backdrop } }}
      >
        <div className={classes.m_Dialog_container}>
          <Typography
            variant="h3"
            className={classes.a_Dialog_title}
          >
            Agree to LeiaLoft® terms <br/> and download content?
          </Typography>
          <div
            className={classes.m_Dialog_content}
          >
            <LlCheckbox
              state={state}
              handleChange={handleChange}

            />
            <Typography className={classes.a_Dialog_text}>
              I have read and agree to the LeiaLoft®&nbsp;<br />
              <LlNavLink
                to='/license-agreement'
                name="License Agreement"/>
                  &nbsp;and&nbsp;
              <LlNavLink
                to='/terms-of-service'
                name="Terms of Service"/>
                .
            </Typography>
          </div>
          <div className={classes.m_Dialog_actions}>
            <Button color="secondary" onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleClose}
              type="submit"
              size="large"
              variant="contained"
              disabled={!state.checked}
              href={download}
              className={classes.a_button}
            >
                Download
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}
