import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import {
  withStyles,
  CssBaseline,
  IconButton,
  useTheme,
  useMediaQuery,
  Button
} from '@material-ui/core'
import CreatorAppBarMenu from './CreatorAppBarMenu'
import HamburgerMenu from '../HamburgerMenu'
import styles from './CreatorAppBarStyles'

const CreatorAppBarContainer = ({ classes, topics, handleMobileMenuClick, handleThemeClick, icon, handleLogOut }) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  function renderAppBarInnerContainer () {
    if (isSmallScreen) {
      return <HamburgerMenu handleClick={handleMobileMenuClick} />
    } else {
      return (
        <Fragment>
          <CreatorAppBarMenu topics={topics} />
          <div>
            <Button
              className={classes.a_Private_LogOut_wrapper}
              variant="contained"
              component={NavLink}
              to="/get-certified"
              style={{ gridColumn: 3 }}
            >
              Get certified
            </Button>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="mode"
              onClick={handleThemeClick}
              className={classes.a_themeSwitch_icon}
            >
              {icon}
            </IconButton>

          </div>
        </Fragment>
      )
    }
  }

  return (
    <Fragment>
      <CssBaseline />
      {renderAppBarInnerContainer()}
    </Fragment>
  )
}

CreatorAppBarContainer.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CreatorAppBarContainer)
