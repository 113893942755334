import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'

export default function HamburgerMenu ({ handleClick }) {
  const theme = useTheme() 
  console.log(theme.palette.type)
  const useStyles = makeStyles({
    m_hamburger_menu_container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      justifySelf: 'end',
      width: '18px',
      height: '14px',
      cursor: 'pointer'
    },
    a_hamburger_menu_line: {
      background: theme.palette.type === 'dark' ? '#fff' : '#1A1A1A',
      borderRadius: '1px',
      height: '2.25px'
    }
  })
  const classes = useStyles()
  return <div className={classes.m_hamburger_menu_container} onClick={handleClick}>
    { [1, 2, 3].map(num => <div key={num} className={classes.a_hamburger_menu_line}></div>) }
  </div>
}
