import React, { Fragment } from 'react'
import LightfieldCard from './LightfieldCard'
import SharedCardHalfMedia from '../sharedComponents/SharedCardHalfMedia'
import {
  makeStyles,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import toolsStyles from '../tools/ToolsStyles'

const SpotlightTool = [
  {
    label: 'discover',
    title: 'The beauty of Lightfields.',
    summary:
      'Lightfield is the next generation medium that lets you experience 3D imagery with a natural sensation of depth and feel for textures, materials and lights — with no eye-wear required. It makes you feel content like never before, adding emotional connection in a digital world, making memories become more present, connections more human, and life, much richer.',
    imageTitle: 'Diamond light video',
    toSubmitPath: true,
    href: 'https://www.leiainc.com/technology/'
  }
]

export default function LightfieldView () {
  console.log(resizeHeight())
  const theme = useTheme()
  const useStyles = makeStyles({
    ...toolsStyles(theme),
    o_container_Lightfield: {
      display: 'grid',
      padding: theme.spacing(3, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(6, 1)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(10.5, 1)
      }
    }
  })
  const classes = useStyles()

  /** Homepage responsive shenanigans */
  const isXlLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  // const isFillLargeScreen = useMediaQuery('(max-width: 1919px) and (min-width: 1480px)')
  // const isInBetween = useMediaQuery('(max-width: 1479px) and (min-width: 1280px)')
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  // const isTroubleSpot = useMediaQuery('(max-width: 1112px) and (min-width: 960px)')
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))
  function resizeHeight () {
    if (isXlLargeScreen) {
      return '29rem'
    } else if (isLargeScreen) {
      return '30rem'
    } else if (isMediumScreen) {
      return '35rem'
    } else if (isSmallScreen) {
      return '25rem'
    } else {
      return '30rem'
    }
  }
  // function resizeHeight () {
  //   if (isXlLargeScreen) {
  //     return '27rem'
  //   } else if (isFillLargeScreen) {
  //     return '26rem'
  //   } else if (isInBetween) {
  //     return '30rem'
  //   } else if (isMediumScreen) {
  //     return '35rem'
  //   } else if (isSmallScreen) {
  //     return '25rem'
  //   } else {
  //     return '30rem'
  //   }
  // }

  return (
    <main className={classes.o_layout_Home}>
      <div className={classes.o_container_Lightfield}>
        <Grid container spacing={2} className={classes.o_grid_container}>
          {SpotlightTool.map(tool => {
            return (
              <Fragment key={tool.title}>
                <LightfieldCard
                  height={resizeHeight()}
                  label={tool.label}
                  fontVariant="h2"
                  title={tool.title}
                  summary={tool.summary}
                  outlinedButton="learn more"
                  gridColumn="2"
                  href={tool.href}
                  // alignSelf="flex-start"
                />
                <SharedCardHalfMedia
                  // image={require('../assets/ezgif.com-gif-maker.gif')}
                  image={require('../assets/videos/diamond_lightfield_clip.mp4')}
                  imageTitle={tool.imageTitle}
                  height={resizeHeight()}
                  component="video"
                />
              </Fragment>
            )
          })}
        </Grid>
      </div>
    </main>
  )
}
