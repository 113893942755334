import React from 'react'
import {
  useTheme,
  makeStyles
} from '@material-ui/core'
import Logo from '../assets/logo/logo'
import toolsStyles from './ToolsStyles'
import Image from '../assets/images/Hero_Light_Texture.png'
import LightImage from '../assets/images/LIGHT_MODE_LEIA_LOFT_TEXTURE_01_RGB.png'

export default function HeroView () {
  const theme = useTheme()
  const getThemeLogo = () => {
    if (theme.palette.type === 'dark') {
      return <Logo name="ll-creator-header-tools-w" />
    } else {
      return <Logo name="ll-creator-header-tools-b" />
    }
  }
  const useStyles = makeStyles({
    ...toolsStyles(theme),
    o_container_Hero: {
      alignItems: 'center',
      margin: '0 auto',
      padding: theme.spacing(8.25, 2),
      backgroundSize: '200%',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(12, 6, 12),
        // backgroundSize: '160%',
        backgroundSize: theme.palette.type === 'dark'
          ? '160%'
          : '100%'
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(18, 0, 18)
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(25, 0, 25)
      },
      backgroundImage: theme.palette.type === 'dark'
        ? 'url(' + Image + ')'
        : 'url(' + LightImage + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: theme.palette.type === 'dark'
        ? 'bottom left'
        : 'center'
    },
    m_container_Logo: {
      maxWidth: 600,
      margin: '0 auto',
      display: 'flex',
      height: '100%',
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 4)
      }
    },
    a_text_Hero: {
      padding: theme.spacing(2, 0, 0)
    }
  })
  const classes = useStyles()
  return (
    <main>
      <div className={classes.o_container_Hero}>
        <div className={classes.m_container_Logo}>
          {getThemeLogo()}
        </div>
      </div>
    </main>
  )
}
