import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core'

const SplitButton = ({
  options,
  defaultSelectedIndex = 1,
  onSelect,
  onClick
}) => {
  const theme = useTheme()
  const isMedScreen = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallScreen = useMediaQuery('(max-width:1100px)')
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(defaultSelectedIndex)

  const handleClick = () => {
    onClick(options[selectedIndex].value)
  }

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index)
    setOpen(false)
    onSelect(options[index].value)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = ({ target }) => {
    if (anchorRef.current && anchorRef.current.contains(target)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        variant="outlined"
        color="inherit"
        ref={anchorRef}
        aria-label="split button"
        style={{
          marginRight: '10px',
          height: '42px'
        }}
      >
        <Button
          onClick={handleClick}
          style={{
            width: isSmallScreen ? '130px' : isMedScreen ? '200px' : '245px',
            fontSize: isSmallScreen && '12px'
          }}
        >
          {options[selectedIndex].label}
        </Button>
        <Button
          color="inherit"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {
            open
              ? <KeyboardArrowUpIcon />
              : <KeyboardArrowDownIcon />
          }
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper style={{
              border: '1px solid white',
              marginTop: '8px',
              marginRight: '6px'
            }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" style={{ padding: '0' }}>
                  {options.map(({ label, value, icon }, index) => (
                    <MenuItem
                      key={value}
                      style={{ fontSize: '14px', letterSpacing: '0.75px' }}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}>
                        <div style={{ marginRight: '16px' }}>
                          {label}
                        </div>
                        {icon}
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

SplitButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.node
  })),
  defaultSelectedIndex: PropTypes.number,
  onSelect: PropTypes.func,
  onClick: PropTypes.func
}

export default SplitButton
