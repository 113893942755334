import { createMuiTheme } from '@material-ui/core/styles'
import { theme, mainTheme } from './MainTheme'

export const lightTheme = createMuiTheme({
  ...theme,
  palette: {
    type: 'light',
    primary: {
      main: '#1a1a1a',
      gray5: '#313131',
      gray4: '#313131',
      gray3: '#4c4c4c',
      gray2: '#d0d0d0',
      gray1: '#f1f1f1',
      gray: '#f2f2f2',
      leiaLight: '#fafafa'
    },
    background: {
      default: '#D0D0D0'
    },
    secondary: {
      main: '#8833F5',
      hover: '#6B2ACC',
      disabled: '#C89DFF'
    },
    error: {
      main: '#EB5757'
    }
  },
  overrides: {
    ...theme.overrides,
    MuiAppBar: {
      colorDefault: {
        backgroundColor: mainTheme.palette.primary.leiaLight
      }
    }
  }
})
