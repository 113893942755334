export const topics = [
  {
    name: 'Learn',
    to: '/learn',
    show: 'header',
    redirect: 'tools-and-documentation',
    subtopic: [
      {
        name: 'Tools & Documentation',
        to: 'tools-and-documentation',
        show: 'default'
      }
    ]
  },
  {
    name: 'Manage',
    to: '/manage',
    show: 'header',
    redirect: 'content',
    subtopic: [
      {
        name: 'Content',
        to: 'content',
        show: 'default'
      },
      {
        name: 'Analytics',
        to: 'analytics',
        show: 'default'
      },
      {
        name: 'Partners',
        to: 'partners',
        show: 'admin'
      },
      {
        name: 'Distribution',
        to: 'distribution',
        show: 'admin'
      },
      {
        name: 'Revenue & Payments',
        to: 'revenue-and-payments',
        show: 'developer'
      }
    ]
  },
  {
    name: 'Support',
    to: '/support',
    show: 'header',
    redirect: 'faq',
    subtopic: [
      {
        name: 'FAQ',
        to: 'faq',
        show: 'default'
      },
      {
        name: 'Forum',
        to: 'forum',
        show: 'default'
      },
      {
        name: 'Contact',
        to: 'contact',
        show: 'default'
      }
    ]
  },
  {
    name: 'Account',
    to: '/account',
    show: 'footer',
    redirect: null,
    subtopic: [
      {
        name: 'Log Out',
        to: '/logout',
        show: false
      },
      {
        name: 'Terms Of Use',
        to: 'terms-of-use',
        show: 'footer'
      },
      {
        name: 'Privacy Policy',
        to: 'privacy-policy',
        show: 'footer'
      },
      {
        name: 'Developer Agreement',
        to: 'developer-agreement',
        show: 'footer'
      }
    ]
  }
]
