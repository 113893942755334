import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './CertificationStyles'
import CssBaseline from '@material-ui/core/CssBaseline'
import HeroView from './HeroView'
import Benefits from './Benefits'
import OverviewView from './OverviewView'
import SubmissionRequirements from './SubmissionRequirements'

const CertificationView = () => {
  return (
    <Fragment>
      <CssBaseline/>
      <HeroView/>
      <Benefits/>
      <OverviewView />
      <SubmissionRequirements />
    </Fragment>
  )
}

CertificationView.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CertificationView)
