import React, { Fragment } from 'react'
import {
  Grid,
  CssBaseline,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core'
import LlNavlink from '../sharedComponents/LlNavLink'

const useStyles = makeStyles((theme) => ({
  t_layout_centered: {
    alignItems: 'center',
    flex: '1 0 auto'
  },
  o_grid_container: {
    padding: '1rem',
    margin: '7rem auto',
    maxWidth: '384px'
  },
  m_form_actions: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'auto 56%',
    paddingRight: '6px',
    marginTop: '2rem',
    '& :first-child': {
      justifySelf: 'flex-start'
    }
  },
  m_Checkbox_container: {
    display: 'grid',
    gridTemplateColumns: '23px auto',
    padding: '6px'
  },
  a_Dialog_text: {
    padding: '0 0 8px 8px'
  }
}))

export default function SuccessView () {
  const classes = useStyles()
  return (
    <Fragment>
      <CssBaseline />
      <main className={classes.t_layout_centered}>
        <Grid container className={classes.o_grid_container}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              gutterBottom
            >
              Content submitted succesfully.
            </Typography>
          </Grid>
          <Grid container justify="flex-start" item>
            <Typography
              variant="subtitle2"
              gutterBottom
            >
              You will receive a follow-up email with certification once your content has been viewed and approved by the LeiaLoft Certification Review Board. Thank you for participating!
              <br /><br/>Submissions that are not immediately approved will receive feedback with resubmission
            </Typography>
          </Grid>
          <div className={classes.m_form_actions}>
            <LlNavlink to="/" name="BACK TO HOME" style={{ fontSize: '14px', alignSelf: 'center' }}/>
            <Button
              size="medium"
              variant="contained"
              target="_blank"
              href="https://forums.leialoft.com/"
            >
              Visit the forum
            </Button>
          </div>

        </Grid>
      </main>
    </Fragment>
  )
}
