import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import {
  makeStyles,
  TextField,
  Typography,
  Button,
  useTheme
} from '@material-ui/core'
import LlCheckbox from '../sharedComponents/LlCheckbox'
import LlNavLink from '../sharedComponents/LlNavLink'
import ReCAPTCHA from 'react-google-recaptcha'
import isUrl from 'validator/lib/isURL'
import isEmail from 'validator/lib/isEmail'
import { NavLink } from 'react-router-dom'

export default function GetCertifiedForm ({
  state,
  onSubmit,
  handleChange
}) {
  const theme = useTheme()
  const useStyles = makeStyles({
    a_input: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: theme.palette.type === 'dark'
          ? '0 0 0 1000px #1a1a1a inset'
          : '0 0 0 1000px #fafafa inset'
      }
    },
    m_form_actions: {
      display: 'grid',
      gridTemplateColumns: 'auto 45%',
      marginTop: '1rem',
      '& :first-child': {
        justifySelf: 'flex-start'
      }
    },
    t_form_display: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    m_Checkbox_container: {
      display: 'grid',
      gridTemplateColumns: '23px auto',
      padding: '6px 0'
    },
    a_Dialog_text: {
      padding: '0 0 8px 8px'
    }
  })
  const classes = useStyles()
  const { register, handleSubmit, errors, getValues } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' })
  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.t_form_display} noValidate>
        <TextField
          id="outlined-adornment"
          label="First name"
          type="text"
          name="firstName"
          margin="normal"
          variant="outlined"
          inputProps={{ maxLength: 50, className: classes.a_input }}
          error={!!errors.firstName}
          inputRef={register({
            pattern: /^[a-zA-Z]+(([. - ' ][a-zA-Z ])?[a-zA-Z ]*)*$/,
            maxLength: 50,
            required: true
          })}
        />
        <Typography variant="caption" color="error">
          {errors.firstName && errors.firstName.type === 'required' && 'Please enter your first name.'}
          {errors.firstName && errors.firstName.type === 'pattern' && 'Your first name can only contain letters.'}
        </Typography>
        <TextField
          id="outlined-adornment"
          label="Last name"
          type="text"
          name="lastName"
          margin="normal"
          variant="outlined"
          inputProps={{ maxLength: 50, className: classes.a_input }}
          error={!!errors.lastName}
          inputRef={register({
            pattern: /^[a-zA-Z]+(([. - ' ][a-zA-Z ])?[a-zA-Z ]*)*$/,
            maxLength: 50,
            required: true
          })}
        />
        <Typography variant="caption" color="error">
          {errors.lastName && errors.lastName.type === 'required' && 'Please enter your last name.'}
          {errors.lastName && errors.lastName.type === 'pattern' && 'Your last name can only contain letters.'}
        </Typography>
        <TextField
          id="outlined-url-input-required"
          label="Content URL 1"
          type="url"
          name="submissionLink1"
          autoComplete="url"
          margin="normal"
          variant="outlined"
          error={!!errors.submissionLink1}
          inputProps={{ className: classes.a_input }}
          inputRef={
            register({
              required: true,
              validate: {
                isUnique: value => value !== getValues().submissionLink2,
                isUrl: (value) => isUrl(value)
              }
            })
          }
        />
        <Typography variant="caption" color="error">
          {errors.submissionLink1 && errors.submissionLink1.type === 'isUnique' && 'Please enter a unique content url'}
          {errors.submissionLink1 && errors.submissionLink1.type === 'required' && 'Please enter your content url address.'}
          {errors.submissionLink1 && errors.submissionLink1.type === 'isUrl' && 'Invalid url address format'}
        </Typography>

        <TextField
          id="outlined-url-input-required"
          label="Content URL 2"
          type="url"
          name="submissionLink2"
          autoComplete="url"
          margin="normal"
          variant="outlined"
          error={!!errors.submissionLink2}
          inputProps={{ className: classes.a_input }}
          inputRef={
            register({
              required: true,
              validate: {
                isUnique: value => value !== getValues().submissionLink1,
                isUrl: (value) => isUrl(value)
              }

            })
          }
        />
        <Typography variant="caption" color="error">
          {errors.submissionLink2 && errors.submissionLink2.type === 'required' && 'Please enter your content url address.'}
          {errors.submissionLink2 && errors.submissionLink2.type === 'isUrl' && 'Invalid url address format'}
          {errors.submissionLink2 && errors.submissionLink2.type === 'isUnique' && 'Please enter a unique content url'}
        </Typography>
        <TextField
          id="outlined-email-input-required"
          label="Email address"
          type="email"
          name="email"
          autoComplete="email"
          margin="normal"
          variant="outlined"
          error={!!errors.email}
          inputProps={{ className: classes.a_input }}
          inputRef={
            register({
              required: true,
              validate: (value) => isEmail(value)
            })
          }
        />
        <Typography variant="caption" color="error">
          {errors.email && errors.email.type === 'required' && 'Please enter your email address.'}
          {errors.email && errors.email.type === 'validate' && 'Invalid email address format'}
        </Typography>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_LEIALOFT_CONSOLE_RECAPTCHA_SITE_KEY}
          size="invisible"
          theme="dark"
        />
        <div className={classes.m_Checkbox_container}>
          <LlCheckbox
            state={state}
            handleChange={handleChange}
          />
          <Typography className={classes.a_Dialog_text}>
              I have read and agree to the LeiaLoft®&nbsp;
            <LlNavLink
              to='/license-agreement'
              name="License Agreement" />
                  ,&nbsp;
            <LlNavLink
              to='/terms-of-service'
              name="Terms of Service"/>
                  &nbsp;and&nbsp;
            <LlNavLink
              to='/privacy-policy'
              name="Privacy Policy"/>
          </Typography>
        </div>
        <div className={classes.m_form_actions}>
          <Button
            color="secondary"
            component={NavLink}
            to="/"
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            type="submit"
            disabled={!state.checked}
          >
            Submit
          </Button>
        </div>
      </form>
    </Fragment>
  )
}
