import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './ToolsStyles'
import CssBaseline from '@material-ui/core/CssBaseline'
import HeroView from './HeroView'
import CtaView from './CtaView'
import GetStartedView from './GetStartedView'
import UnleashTheMagicView from './UnleashTheMagicView'

const ToolsView = () => {
  return (
    <Fragment>
      <CssBaseline/>
      <HeroView/>
      <GetStartedView/>
      <UnleashTheMagicView/>
      <CtaView />
    </Fragment>
  )
}

ToolsView.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ToolsView)
