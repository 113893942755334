import React, { Fragment } from 'react'
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  useTheme,
  Button
} from '@material-ui/core'
import { SharedGridList, StyledTypography } from './FooterViewComponents'
import { creatorTopics } from '../creatorTopics'

const icons = [
  {
    string: 'facebook icon',
    white: require('../../assets/icons/facebookWhite.svg'),
    black: require('../../assets/icons/facebookBlack.svg'),
    href: 'https://www.facebook.com/leiainc',
    theme: 'dark'
  },
  {
    string: 'twitter icon',
    white: require('../../assets/icons/twitterWhite.svg'),
    black: require('../../assets/icons/twitterBlack.svg'),
    href: 'https://twitter.com/leiainc',
    theme: 'dark'
  },
  {
    string: 'youtube icon',
    white: require('../../assets/icons/youtubeWhite.svg'),
    black: require('../../assets/icons/youtubeBlack.svg'),
    href: 'https://www.youtube.com/leiainc',
    theme: 'dark'
  },
  {
    string: 'linkedin icon',
    white: require('../../assets/icons/linkedinWhite.svg'),
    black: require('../../assets/icons/linkedinBlack.svg'),
    href: 'https://www.linkedin.com/company/leia-inc-/',
    theme: 'dark'
  }

]

export default function CreatorFooterView ({ user, handleLogOut, topics }) {
  const theme = useTheme()
  const useStyles = makeStyles({
    o_layout_Footer: {
      flexShrink: 0,
      // paddingBottom: '4em',
      // paddingTop: '2.5rem',
      padding: '2.5rem 3rem 4rem 2rem',
      [theme.breakpoints.up('sm')]: {
        paddingTop: '4em'
      }
    },
    m_footerList_container: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '2rem',
      [theme.breakpoints.up('sm')]: {
        padding: '2rem 0 3rem'
      }
    },
    a_text_copyright_wrapper: {
      paddingTop: '2rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse'
      }
    },
    a_icon_button_container: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '2rem',
        '& :first-child': {
          marginLeft: '-12px'
        }
      }
    },
    a_icon: {
      height: '21px'
    }
  })
  const classes = useStyles()
  const leialoft = creatorTopics.find(({ name }) => name === 'leialoft®')
  const leiainc = creatorTopics.find(({ name }) => name === 'Leia Inc')
  const legal = creatorTopics.find(({ name }) => name === 'Legal')
  return (
    <Fragment>
      <div className={classes.o_layout_Footer} style={{ backgroundColor: (theme.palette.type === 'dark' ? '#1a1a1a' : '#f2f2f2') }}>
        <Container >
          <Grid container>
            <Fragment>
              <Grid item xs={12} sm={3} className={classes.m_footerList_container}>
                <StyledTypography
                  topicName={creatorTopics[0].name}
                >
                </StyledTypography>
                {leialoft.subtopic.map((sub, id) =>
                  <SharedGridList
                    key={id}
                    to={sub.to}
                    subtopicName={sub.name}
                    href={sub.href}
                  />)}
              </Grid>
              <Grid item xs={12} sm={3} className={classes.m_footerList_container}>
                <StyledTypography
                  topicName={creatorTopics[1].name}
                >
                </StyledTypography>
                {leiainc.subtopic.map((sub, id) =>
                  <SharedGridList
                    key={id}
                    to={sub.to}
                    subtopicName={sub.name}
                    href={sub.href}
                  />)}
              </Grid>
              <Grid item xs={12} sm={3} className={classes.m_footerList_container}>
                <StyledTypography
                  topicName={creatorTopics[2].name}
                >
                </StyledTypography>
                {legal.subtopic.map((sub, id) =>
                  <SharedGridList
                    key={id}
                    to={sub.to}
                    subtopicName={sub.name}
                    href={sub.href}
                  />)}
              </Grid>
            </Fragment>
          </Grid>
          <Grid container justify="space-between"
            className={classes.a_text_copyright_wrapper}
          >
            <Typography
              variant="overline"
              className={classes.a_text_copyright}
            >
                ©{new Date().getFullYear()} Leia inc. 2020 | Sand hill road, ste 100 | menlo park, ca 94025
            </Typography>
            <div className={classes.a_icon_button_container}>
              {icons.map((el, id) => {
                return (
                  <Button
                    key={id}
                    href={el.href}
                  >
                    {
                      theme.palette.type === 'dark'
                        ? <img
                          alt={el.string}
                          src={el.white}
                          className={classes.a_icon}
                        />
                        : <img
                          alt={el.string}
                          src={el.black}
                          className={classes.a_icon}
                        />
                    }
                  </Button>
                )
              })}
            </div>
          </Grid>
        </Container>
      </div>
    </Fragment>
  )
}
