import { InMemoryCache, makeVar, gql } from '@apollo/client'

const userInitialValue = (localStorage.getItem('permissions'))

export const userVar = makeVar(userInitialValue)

// create InMemoryCache object
export const cache = new InMemoryCache({
  // provide typePolicies 'options' object to InMemoryCache constructor to customize cache behavior on a type-by-type basis
  typePolicies: {
    // creating a Query to set up Reactivity
    Query: {
      fields: {
        // defining field policy for getPermissions
        getPermissions: {
          // read function returns value of reactive variable wherever 'getPermissions' is queried
          read () {
            return userVar()
          }
        }
      }
    }
  }
})

export const GET_USER_PERMISSIONS = gql`
  query GetUserPermissions {
    getPermissions 
  }
`
