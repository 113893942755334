import homeViewStyles from '../home/HomeViewStyles'

function pxToRem (value) {
	return `${value / 16}rem`
}

export default theme => ({
	...homeViewStyles(theme),
	a_text_title: {
		gridRow: '1'
	},
	a_text_subtitle: {
		gridRow: '1',
		gridColumn: '2 / span 2',
		alignSelf: 'end',
		marginBottom: '1.3em',
		fontSize: pxToRem(13.5),
		textTransform: 'uppercase',
		paddingLeft: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			marginBottom: '1em'
		},
		[theme.breakpoints.up('lg')]: {
			marginBottom: '2em'
		}
	},
	a_text_body1: {
		gridColumn: '1 / span 2',
    // paddingBottom: '1rem',
		[theme.breakpoints.up('md')]: {
			fontSize: pxToRem(18.2)
		},
	},
	o_card_layout: {
		display: 'grid',
    gridTemplateRows: 'auto 20%',
		padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
		'&:last-child': {
			paddingBottom: theme.spacing(2)
		},
		[theme.breakpoints.up('sm')]: {
			padding: `${theme.spacing(5)}px ${theme.spacing(6)}px`,
			'&:last-child': {
				paddingBottom: theme.spacing(4)
			}
		},
    [theme.breakpoints.down('xs')]: {
      padding: 16
    }
	},
	// m_cardAction_btn_container: {
	// 	alignSelf: 'end',
	// 	display: 'grid',
	// 	gridTemplateColumns: '50% auto',
	// 	justifyItems: 'start'
	// },
	m_cardMedia: {
		height: '100%',
		objectFit: 'cover'
	}
})
